// src/components/HeroSection.js
import React, { useEffect, useRef, useState } from 'react';
import starImage from '../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png';
import 'animate.css';
import Hero from '../../assets/img/offer-bg.png';

import offer_1 from '../../assets/img/offer-1.png';
import offer_2 from '../../assets/img/offer-2.png';
import offer_3 from '../../assets/img/offer-3.png';
import offer_4 from '../../assets/img/offer-4.png';
import offer_5 from '../../assets/img/offer-5.png';

const HeroSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false); // Set to false when the section is not visible
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className='container py-5'>
            <div ref={sectionRef}>
                <div className='text-center mb-5'>
                    <p className={`fs-14 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                        Limited Availability
                    </p>
                    <h1 className={`fs-40 fw-600 w-75 mx-auto text-theme ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`} >
                        Celebrate Our Opening with Exclusive Launch Offers for the first 50 patients
                    </h1>
                </div>

                <div className={`card bg-light mb-4 rounded-3 border-0 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                    <div className='card-body p-5'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 mb-3'>
                                <span class="badge bg-golden mb-3 rounded-pill px-4" > Offer 1 </span >
                                <h2 className='fs-40 mt-3'>
                                    £25 Off on New Patient Exam
                                </h2>
                                <p>
                                    Join our practice with a comprehensive new patient exam, cleaning, and X-rays at a special discounted rate.
                                </p>
                                <a className='btn btn-theme rounded-pill fs-14 w-50 mt-4'>Claim Now</a>
                            </div>
                            <div className='col-lg-7'>
                                <img src={offer_1} className='img-fluid w-100 ps-0 ps-lg-5'></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`card bg-light mb-4 rounded-3 border-0 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                    <div className='card-body p-5'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 mb-3'>
                                <span class="badge bg-golden mb-3 rounded-pill px-4" > Offer 2 </span >
                                <h2 className='fs-40 mt-3'>
                                    Free Philips Electric Tooth Brush for Dental Plan registrations
                                </h2>
                                <p>
                                    Enhance your smile with 20% off cosmetic treatments, including veneers and bonding, for a truly radiant look.
                                </p>
                                <a className='btn btn-theme rounded-pill fs-14 w-50 mt-4'>Claim Now</a>
                            </div>
                            <div className='col-lg-7'>
                                <img src={offer_2} className='img-fluid w-100 ps-0 ps-lg-5'></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`card bg-light mb-4 rounded-3 border-0 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                    <div className='card-body p-5'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 mb-3'>
                                <span class="badge bg-golden mb-3 rounded-pill px-4" > Offer 3 </span >
                                <h2 className='fs-40 mt-3'>
                                    20% off all Invisalign® Treatments
                                </h2>
                                <p>
                                Start your journey to a perfect smile with a free Invisalign consultation, where our experts guide you every step of the way.
                                </p>
                                <a className='btn btn-theme rounded-pill fs-14 w-50 mt-4'>Claim Now</a>
                            </div>
                            <div className='col-lg-7'>
                                <img src={offer_3} className='img-fluid w-100 ps-0 ps-lg-5'></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`card bg-light mb-4 rounded-3 border-0 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                    <div className='card-body p-5'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 mb-3'>
                                <span class="badge bg-golden mb-3 rounded-pill px-4" > Offer 4 </span >
                                <h2 className='fs-40 mt-3'>
                                    30% Off Teeth Whitening
                                </h2>
                                <p>
                                    Brighten your smile at half the cost! Our professional teeth whitening offers stunning, lasting results you’ll love.
                                </p>
                                <a className='btn btn-theme rounded-pill fs-14 w-50 mt-4'>Claim Now</a>
                            </div>
                            <div className='col-lg-7'>
                                <img src={offer_4} className='img-fluid w-100 ps-0 ps-lg-5'></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`card bg-light mb-4 rounded-3 border-0 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                    <div className='card-body p-5'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 mb-3'>
                                <span class="badge bg-golden mb-3 rounded-pill px-4" > Offer 5 </span >
                                <h2 className='fs-46 mt-3'>
                                    £25 Off on New Patient Exam
                                </h2>
                                <p>
                                    Join our practice with a comprehensive new patient exam, cleaning, and X-rays at a special discounted rate.
                                </p>
                                <a className='btn btn-theme rounded-pill fs-14 w-50 mt-4'>Claim Now</a>
                            </div>
                            <div className='col-lg-7'>
                                <img src={offer_5} className='img-fluid w-100 ps-0 ps-lg-5'></img>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default HeroSection;
