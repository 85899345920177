import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import docImage from '../../assets/img/doc-white.png'; // Import your image

const DentalCareSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null); // Reference to the section for intersection observer

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Trigger animation when in view
                } else {
                    setIsVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 } // Adjust threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="bg-theme">
            <div className="container">
                <div className="row align-items-center">

                    <div className={`col-md-6 mt-4 animate__animated ${isVisible ? 'animate__fadeInLeft' : 'animate__fadeOut'}`}>
                        <img src={docImage} className="img-fluid" alt="Dental Care" />
                    </div>

                    <div className={`col-md-6 text-light mt-4 animate__animated ${isVisible ? 'animate__fadeInRight' : 'animate__fadeOut'}`}>
                        <p>Dental Care You'll Love</p>
                        <h1 className="fs-48">Transforming Smiles with Artistry and Precision</h1>

                        <p>
                            Porcelain veneers are bespoke, wafer-thin shells crafted from high-quality ceramic that are bonded to the front surface of your teeth. 
                            They are renowned for their ability to correct discolouration, chips, gaps, and misalignments with a natural, durable finish.
                        </p>
                        <p>
                            Opting for porcelain veneers means choosing a long-term solution that improves the appearance of your smile while preserving the natural structure of your teeth.
                            Whilst it could involve some tooth preparation, the colour, shape, and size is custom made to ensure a natural look and feel. 
                        </p>

                        <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">Book an Appointment</a>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default DentalCareSection;
