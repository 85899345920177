import React, { useEffect, useState } from 'react';
import '../../assets/css/team.css';
import 'animate.css';
import cbct from '../../assets/img/cbct.jpg';
import intraoral from '../../assets/img/oral-scanner.jpg';

const ProfileSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <section>
            <div className="container mt-5">
                <div className='text-center mb-5'>
                    <h1 className='text-theme'> Our <span className='text-golden'>Technology</span></h1>
                </div>

                {/* First Row */}
                <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
                    <div className="col-md-6 p-lg-5">
                        <img
                            src={cbct}
                            alt="CBCT Scanner"
                            className={`shadow--golden-1 img-fluid animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}
                            style={{borderTopLeftRadius: '7.5rem'}}
                        />
                    </div>
                    <div className="col-md-6">
                        <h2 className={`fs-40 fw-bold animate__animated ${isVisible ? 'animate__fadeInRight' : ''} mt-4`}>
                            CBCT Scanner
                        </h2>
                        <p className={`fs-17 animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-1s' : ''}`}>
                            Our practice is equipped with a state-of-the-art Cone Beam Computed Tomography (CBCT) scanner, which allows us to capture highly detailed 3D images of your teeth, jawbone, and surrounding structures. <br></br><br></br> This advanced imaging technology provides a comprehensive view that traditional X-rays simply cannot match, enabling us to diagnose and plan treatments with incredible precision. <br></br><br></br>
                            Whether you're considering dental implants, orthodontics, or any other complex dental procedure, our CBCT scanner ensures that we can tailor your treatment to your unique anatomy, improving outcomes and enhancing your overall experience.
                        </p>
                    </div>
                </div>

                {/* Second Row */}
                <div className="row align-items-center mb-4 p-lg-5 p-3">
                    <div className="col-md-6">
                        <h2 className={`fs-40 text-end fw-bold animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}>
                            Intraoral Scanner
                        </h2>
                        <p className={`fs-17 text-end animate__animated ${isVisible ? 'animate__fadeInLeft animate__delay-1s' : ''}`}>
                        Say goodbye to uncomfortable and messy dental impressions with our advanced intraoral scanner. This innovative device captures a precise digital impression of your teeth in mere minutes, using a small, handheld wand that comfortably fits in your mouth. <br></br><br></br>
                        The scanner's detailed 3D images are instantly displayed on a screen, allowing us both to visualise your oral health in real time. This technology not only improves accuracy but also speeds up the creation of custom dental appliances, such as crowns, aligners, and night guards, ensuring a faster and more comfortable treatment process.
                        </p>
                    </div>
                    <div className="col-md-6 p-5">
                        <img
                            src={intraoral}
                            alt="Intraoral Scanner"
                            className={`img-fluid shadow-golden-2 profile-image animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}
                        />
                    </div>
                </div>
            </div>

        </section>
    );
};

export default ProfileSection;
