import React from 'react';
import logo from '../../assets/img/3.png'; // Adjust the path as needed

const Navbar = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid px-4">
                    <a className="navbar-brand animate__animated animate__zoomIn" href="/">
                        <img src={logo} alt="Logo" height="60" />
                    </a>
                    <button
                        className="navbar-toggler d-lg-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsibleNavId"
                        aria-controls="collapsibleNavId"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon fs-12"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavId">
                        <div className='mx-auto'></div>
                        <div className="d-flex align-items-center my-2 my-lg-0 me-1">
                            <a href="/contact-us" className="fs-17 fw-800 text-theme me-3 animate__animated animate__zoomIn" >
                                (512) 647-6045
                            </a>
                            <a href="/contact-us" className="fs-14 btn btn-theme animate__animated animate__zoomIn" >
                                Book an Appointment
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
