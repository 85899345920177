import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

import icon1 from '../../assets/img/Frame.png';
import icon2 from '../../assets/img/orthodontic 1.png';
import icon3 from '../../assets/img/Frame-11.png';
import icon4 from '../../assets/img/Group...png';
import icon5 from '../../assets/img/Frame12.png';

const DiscreetBenefits = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    const benefits = [
        {
            number: icon1,
            title: "Discreet and Comfortable",
            description: "Made from a medical-grade translucent plastic, Invisalign® is lightweight and discreet. While wearing it, the custom aligner is virtually invisible, and your natural smile shines through. The smooth plastic feels comfortable, and the gentle forces it creates are so subtle you’ll barely notice you’re wearing them, even when you’re sleeping.",
        },
        {
            number: icon2,
            title: "Convenience at the Core",
            description: "When it comes to food, you can quickly remove the aligner so no need to worry about meals becoming trapped in your aligner. Invisalign® is removable. Just take it out to eat or brush your teeth or to capture quick photos and then pop them back in.",
        },
        {
            number: icon3,
            title: "Versatility Guaranteed",
            description: "Many people assume teeth straightening is for teens or the super rich - it’s not. With comfort, discretion, and convenience, Invisalign® is the perfect choice, whatever your age. Not only this, but Invisalign® can help fix many orthodontic problems.",
        },
        {
            number: icon4,
            title: "Outstanding Outcomes",
            description: "You’ll love the outcomes Invisalign® offers. Many patients find themselves unconsciously smiling a lot more. And when you love your smile, it can naturally boost your self-esteem.",
        },
        {
            number: icon5,
            title: "A Healthier Smile",
            description: "Cavities, gum disease, and infections are all potential risks if you have misaligned teeth. When teeth are hard to clean, bacteria builds up and straightening your teeth makes it easier to clean your teeth and therefore less chance of bacteria building up.",
        }
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ minHeight: '95vh' }}>
            <div>
                <div className="text-center mb-5 mt-5">
                    <h1 className={`text-theme animate__animated ${isVisible ? 'animate__fadeIn' : ''}`}>
                        Discreet Benefits
                    </h1>
                </div>

                <div className="container mt-5 align-items-stretch">
                    <div className="row justify-content-center">
                        {benefits.map((benefit, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 text-center d-flex justify-content-center animate__animated ${isVisible ? 'animate__fadeInUp' : ''}`} style={{ backgroundColor: '#F3F4F6' }}>
                                    <div className="card-body">
                                        <div style={{ height: '50px', width: '50px' }} className="bg-theme rounded-3 mb-2 d-flex justify-content-center align-items-center mx-auto">
                                            <img src={benefit.number} className='img-fluid'></img>
                                        </div>
                                        <div className="fs-18 fw-600">{benefit.title}</div>
                                        <p className="fs-14">{benefit.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DiscreetBenefits;
