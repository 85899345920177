// src/components/HeroSection.js
import React, { useEffect, useRef, useState } from 'react';
import starImage from '../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png';
import 'animate.css';
import Hero from '../../assets/img/offer-bg.png';

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false); // Set to false when the section is not visible
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className='container-fluid d-flex justify-content-center align-items-center' 
      style={{
        height: '100vh',
        backgroundImage: `url(${Hero})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <div className="row hero-1 align-items-center" ref={sectionRef}>
        <div className="col-lg-12">
          <div className="d-flex ps-0 ps-md-5 align-items-center">
            <div className="p-0 p-md-4 p-md-5 mt-5">
              <div className=''>
                <h1
                  className={`fs-44 fw-400 text-theme ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                  Welcom to Regent Dental- <br />
                  A New <i className='fw-800'>Smile</i> Awaits!
                </h1>
                <p className={`pe-0 fs-16 pe-md-5 mt-4 w-75 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`} >
                  Proudly serving our community with advanced care, personalized to you.
                </p>
              </div>
              <div className="text-center">
                <a
                  href="/contact-us"
                  className={`btn btn-theme w-75 mt-3 shadow rounded-pill ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                  Explore Our Limited-Time Launch Offers
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
