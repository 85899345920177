// src/components/SmileSection.js
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/pp.css';

const SmileSection = () => {

    return (
        <div className="WordSection1 container mt-5">
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Welcome to the Regent Dental Ltd (Regent Dental) privacy notice. Regent
                    Dental respects your privacy and is committed to protecting your personal
                    data. This privacy notice will inform you as to how we look after your
                    personal data when you visit our website (regardless of where you visit it
                    from) and tell you about your privacy rights and how the law protects you.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    Please also use the Glossary to understand the meaning of some of the
                    terms used in this privacy notice.
                    <br />
                    <br />
                    <b>CONTENTS</b>
                    <br />
                    1. Important information and who we are
                    <br />
                    1.1 Purpose of this privacy notice
                    <br />
                    1.2 Controller
                    <br />
                    1.3 Contact details
                    <br />
                    1.4 Changes to the privacy notice and your duty to inform us of changes
                    <br />
                    1.5 Third-party links
                    <br />
                    2. The data we collect about you
                    <br />
                    2.1 If you fail to provide personal data
                    <br />
                    3. How is your personal data collected?
                    <br />
                    4. How we use your personal data
                    <br />
                    4.1 Purposes for which we will use your personal data
                    <br />
                    4.2 Marketing
                    <br />
                    4.3 Promotional offers from us
                    <br />
                    4.4 Third-party marketing
                    <br />
                    4.5 Opting out
                    <br />
                    4.6 Cookies
                    <br />
                    4.7 Change of purpose
                    <br />
                    5. Disclosures of your personal data
                    <br />
                    6. International transfers
                    <br />
                    7. Data security
                    <br />
                    8. Data retention
                    <br />
                    8.1 How long will you use my personal data for?
                    <br />
                    9. Your legal rights
                    <br />
                    9.1 No fee usually required
                    <br />
                    9.2 What we may need from you
                    <br />
                    9.3 Time limit to respond
                    <br />
                    10. Glossary
                    <br />
                    10.1 Lawful basis
                    <br />
                    10.2 Third parties
                    <br />
                    11. Your legal rights
                    <br />
                    12. Cookies notice
                    <br />
                    ‍<br />
                    <b>
                        1. IMPORTANT INFORMATION AND WHO WE ARE
                        <br />
                        ‍<br />
                        1.1 Purpose of this privacy notice
                        <br />
                    </b>
                    This privacy notice aims to give you information on how Regent Dental
                    collects and processes your personal data through your use of this
                    website, including any data you may provide through this website when you
                    sign up to our newsletter or request information on our services.
                    <br />
                    This website is not intended for children and we do not knowingly collect
                    data relating to children.
                    <br />
                    It is important that you read this privacy notice together with any other
                    privacy notice or fair processing notice we may provide on specific
                    occasions when we are collecting or processing personal data about you so
                    that you are fully aware of how and why we are using your data. This
                    privacy notice supplements the other notices and is not intended to
                    override them.
                    <br />
                    <br />
                    <b>
                        1.2 Controller
                        <br />
                    </b>
                    Regent Dental: is the controller and responsible for your personal data
                    (collectively referred to as [“COMPANY”], “we”, “us” or “our” in this
                    privacy notice).
                    <br />
                    If you have any questions about this privacy notice, including any
                    requests to exercise your legal rights, please contact us using the
                    details set out below.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    <b>
                        1.3 Contact details
                        <br />
                    </b>
                    Our full details are:
                    <br />
                    Full name of legal entity: Regent Dental Ltd
                    <br />
                    Email address:&nbsp;pm@regent-dental.co.uk
                    <br />
                    Postal address: 2a Regent Road, Ilkley, LS29 9EA
                    <br />
                    You have the right to make a complaint at any time to the Information
                    Commissioner’s Office (ICO), the UK supervisory authority for data
                    protection issues (www.ico.org.uk). We would, however, appreciate the
                    chance to deal with your concerns before you approach the ICO, so please
                    contact us in the first instance.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    <b>
                        1.4 Changes to the privacy notice and your duty to inform us of changes
                        <br />
                    </b>
                    This version was last updated on 1/11/2020
                    <br />
                    It is important that the personal data we hold about you is accurate and
                    current. Please keep us informed if your personal data changes during your
                    relationship with us.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    <b>
                        1.5 Third-party links
                        <br />
                    </b>
                    The Regent Dental website www.regent-dental.co.uk may include links to
                    third-party websites, plug-ins and applications. Clicking on those links
                    or enabling those connections may allow third parties to collect or share
                    data about you. We do not control these third-party websites and are not
                    responsible for their privacy statements. When you leave our website, we
                    encourage you to read the privacy notice of every website you visit.
                    <br />
                    <br />
                    <b>
                        2. THE DATA WE COLLECT ABOUT YOU
                        <br />
                        ‍<br />
                    </b>
                    Personal data, or personal information, means any information about an
                    individual from which that person can be identified. It does not include
                    data where the identity has been removed (anonymous data). We may collect,
                    use, store and transfer different kinds of personal data about you which
                    we have grouped together follows:
                </span>
            </p>
            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Identity data includes first name, last name, username or similar
                    identifier, title and job title.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Contact data includes postal address, email address and telephone numbers.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Technical data includes internet protocol (IP) address, your login data,
                    browser type and version, time zone setting and location, browser plug-in
                    types and versions, operating system and platform and other technology on
                    the devices you use to access our website.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Profile data includes your interests, preferences, feedback and survey
                    responses.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Usage data includes information about how you use our website, products
                    and services.
                </span>
            </p>
            <p className="MsoListParagraphCxSpLast" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Marketing and communications data includes your preferences in receiving
                    marketing from us and our third parties and your communication
                    preferences.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    We also collect, use and share aggregated data such as statistical or
                    demographic data for any purpose. Aggregated data may be derived from your
                    personal data but is not considered personal data in law as this data does
                    not directly or indirectly reveal your identity. For example, we may
                    aggregate your usage data to calculate the percentage of users accessing a
                    specific website feature. However, if we combine or connect aggregated
                    data with your personal data so that it can directly or indirectly
                    identify you, we treat the combined data as personal data which will be
                    used in accordance with this privacy notice. We do not collect any special
                    categories of personal data about you (this includes details about your
                    race or ethnicity, religious or philosophical beliefs, sex life, sexual
                    orientation, political opinions, trade union membership, information about
                    your health and genetic and biometric data). Nor do we collect any
                    information about criminal convictions and offences.
                    <br />
                    <br />
                    <b>
                        2.1 If you fail to provide personal data
                        <br />
                    </b>
                    Where we need to collect personal data by law, or under the terms of a
                    contract we have with you and you fail to provide that data when
                    requested, we may not be able to perform the contract we have or are
                    trying to enter into with you (for example, to provide you with goods or
                    services). In this case, we may have to cancel a product or service you
                    have with us, but we will notify you if this is the case at the time.
                    <br />
                    <br />
                    <b>
                        3. HOW IS YOUR PERSONAL DATA COLLECTED?
                        <br />
                        ‍<br />
                    </b>
                    We use different methods to collect data from and about you including
                    through:
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Direct interactions. You may give us your identity and contact data by
                    filling in forms or by corresponding with us by post, phone, email or
                    otherwise. This includes personal data you provide when you:
                </span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: '"Courier New"' }}>
                    o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">apply for services;</span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: '"Courier New"' }}>
                    o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">subscribe to our service or publications;</span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: '"Courier New"' }}>
                    o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">request marketing to be sent to you; or</span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: '"Courier New"' }}>
                    o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">give us some feedback.</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: "72.0pt" }}>
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Automated technologies or interactions. As you interact with our website,
                    we may automatically collect technical data about your equipment, browsing
                    actions and patterns. We collect this personal data by using cookies and
                    other similar technologies. We may also receive technical data about you
                    if you visit other websites employing our cookies. Please see our cookies
                    notice.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Third parties or publicly available sources. We may receive personal data
                    about you from various third parties and public sources as set out below:
                </span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: '"Courier New"' }}>
                    o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">Technical data from the following parties:</span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "108.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: "Wingdings" }}>
                    §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    analytics providers such as Google based outside the EU; and
                </span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "108.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: "Wingdings" }}>
                    §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    advertising networks and events based inside the EU.
                </span>
            </p>
            <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "108.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: "Wingdings" }}>
                    §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Identity and contact data from data brokers or aggregators such as event
                    managers and marketing lead providers based inside the EU.
                </span>
            </p>
            <p
                className="MsoListParagraphCxSpLast"
                style={{ marginLeft: "108.0pt", textIndent: "-18.0pt" }}
            >
                <span lang="EN-GB" style={{ fontFamily: "Wingdings" }}>
                    §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Identity and contact data from publicly availably sources such as
                    Companies House and the Electoral Register based inside the EU.
                    <br />‍
                </span>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">4. HOW WE USE YOUR PERSONAL DATA</span>
                </b>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">
                        <br />
                    </span>
                </b>
                <span lang="EN-GB">
                    We will only use your personal data when the law allows us to. Most
                    commonly, we will use your personal data in the following circumstances:
                </span>
            </p>
            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Where we need to perform the contract we are about to enter into or have
                    entered into with you.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Where it is necessary for our legitimate interests (or those of a third
                    party) and your interests and fundamental rights do not override those
                    interests.
                </span>
            </p>
            <p className="MsoListParagraphCxSpLast" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Where we need to comply with a legal or regulatory obligation.
                    <br />
                    Generally, we do not rely on consent as a legal basis for processing your
                    personal data other than in relation to sending third party direct
                    marketing communications to you via email, text message or postage. You
                    have the right to withdraw consent to marketing at any time by contacting
                    us at&nbsp;pm@regent-dental.co.uk.
                    <br />‍
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    <b>
                        4.1 Purposes for which we will use your personal data
                        <br />
                    </b>
                    We have set out below, in a table format, a description of all the ways we
                    plan to use your personal data, and which of the legal bases we rely on to
                    do so. We have also identified what our legitimate interests are where
                    appropriate.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    Note that we may process your personal data for more than one lawful
                    ground depending on the specific purpose for which we are using your data.
                    Please contact us at&nbsp;pm@regent-dental.co.uk&nbsp;if you need details
                    about the specific legal ground we are relying on to process your personal
                    data where more than one ground has been set out in the table below.
                    <br />
                    <br />
                    <br />
                </span>
            </p>
            <table
                className="MsoNormalTable"
                border={1}
                cellSpacing={0}
                cellPadding={0}
                style={{ borderCollapse: "collapse", border: "none" }}
            >
                <tbody>
                    <tr>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <b>
                                    <span lang="EN-GB">Purpose/Activity</span>
                                </b>
                            </p>
                        </td>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderLeft: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <b>
                                    <span lang="EN-GB">Type of data</span>
                                </b>
                            </p>
                        </td>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderLeft: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <b>
                                    <span lang="EN-GB">
                                        Lawful basis for processing including basis of legitimate
                                        interest
                                    </span>
                                </b>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderTop: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">To register you as a new customer</span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Identity
                                    <br />
                                    (b) Contact
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">Performance of a contract with you</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderTop: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    To manage our relationship with you which will include:
                                    <br />
                                    (a) Notifying you about changes to our terms or privacy policy
                                    <br />
                                    (b) Asking you to leave a review or take a survey
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Identity
                                    <br />
                                    (b) Contact
                                    <br />
                                    (c) Profile
                                    <br />
                                    (d) Marketing and Communications
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Performance of a contract with you
                                    <br />
                                    (b) Necessary to comply with a legal obligation
                                    <br />
                                    (c) Necessary for our legitimate interests (to keep our records
                                    updated and to study how customers use our products/services)
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderTop: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    To administer and protect our business and our website (including
                                    troubleshooting, data analysis, testing, system maintenance,
                                    support, reporting and hosting of data)
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Identity
                                    <br />
                                    (b) Contact
                                    <br />
                                    (c) Technical
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Necessary for our legitimate interests (for running our
                                    business, provision of administration and IT services, network
                                    security, to prevent fraud and in the context of a business
                                    reorganisation or group restructuring exercise)
                                    <br />
                                    (b) Necessary to comply with a legal obligation
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderTop: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    To deliver relevant website content and advertisements to you and
                                    measure or understand the effectiveness of the advertising we
                                    serve to you
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Identity
                                    <br />
                                    (b) Contact
                                    <br />
                                    (c) Profile
                                    <br />
                                    (d) Usage
                                    <br />
                                    (e) Marketing and Communications
                                    <br />
                                    (f) Technical
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    Necessary for our legitimate interests (to study how customers use
                                    our products/services, to develop them, to grow our business and
                                    to inform our marketing strategy)
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderTop: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    To use data analytics to improve our website, products/services,
                                    marketing, customer relationships and experiences
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Technical
                                    <br />
                                    (b) Usage
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    Necessary for our legitimate interests (to define types of
                                    customers for our products and services, to keep our website
                                    updated and relevant, to develop our business and to inform our
                                    marketing strategy)
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                border: "solid windowtext 1.0pt",
                                borderTop: "none",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    To make suggestions and recommendations to you about goods or
                                    services that may be of interest to you
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    (a) Identity
                                    <br />
                                    (b) Contact
                                    <br />
                                    (c) Technical
                                    <br />
                                    (d) Usage
                                    <br />
                                    (e) Profile
                                </span>
                            </p>
                        </td>
                        <td
                            style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "solid windowtext 1.0pt",
                                borderRight: "solid windowtext 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{ textAlign: "center" }}
                            >
                                <span lang="EN-GB">
                                    Necessary for our legitimate interests (to develop our
                                    products/services and grow our business)
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    <b>
                        4.2 Marketing
                        <br />
                    </b>
                    We strive to provide you with choices regarding certain personal data
                    uses, particularly around marketing and advertising.
                    <br />
                    <br />
                    <b>
                        4.3 Promotional offers from us
                        <br />
                    </b>
                    We may use your identity, contact, technical, usage and profile data to
                    form a view on what we think you may want or need, or what may be of
                    interest to you. This is how we decide which products, services and offers
                    may be relevant for you (we call this marketing).
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    You will receive marketing communications from us if you have requested
                    information from us or if you provided us with your details when you
                    subscribe for news and updates, in each case, you have not opted out of
                    receiving that marketing.
                    <br />
                    <br />
                    <b>
                        4.4 Third-party marketing
                        <br />
                    </b>
                    We will get your express opt-in consent before we share your personal data
                    with any company outside of Regent Dental for marketing purposes.
                    <br />
                    <br />
                    <b>
                        4.5 Opting out
                        <br />
                    </b>
                    You can ask us or third parties to stop sending you marketing messages at
                    any time by following the opt-out links on any marketing message sent to
                    you or by contacting us at&nbsp;pm@regent-dental.co.uk.
                    <br />
                    <br />
                    <b>
                        4.6 Cookies
                        <br />
                    </b>
                    You can set your browser to refuse all or some browser cookies, or to
                    alert you when websites set or access cookies. If you disable or refuse
                    cookies, please note that some parts of our website may become
                    inaccessible or not function properly. For more information about the
                    cookies we use, please see cookies notice.
                    <br />
                    <br />
                    <b>
                        4.7 Change of purpose
                        <br />
                    </b>
                    We will only use your personal data for the purposes for which we
                    collected it, unless we reasonably consider that we need to use it for
                    another reason and that reason is compatible with the original purpose. If
                    you wish to get an explanation as to how the processing for the new
                    purpose is compatible with the original purpose, please contact us
                    at&nbsp;pm@regent-dental.co.uk.
                    <br />
                    If we need to use your personal data for an unrelated purpose, we will
                    notify you and we will explain the legal basis which allows us to do so.
                    <br />
                    Please note that we may process your personal data without your knowledge
                    or consent, in compliance with the above rules, where this is required or
                    permitted by law.
                    <br />
                    <br />
                    <b>5. DISCLOSURES OF YOUR PERSONAL DATA</b>
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    We may have to share your personal data with the parties set out below for
                    the purposes set out in the glossary section, this includes:
                </span>
            </p>
            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">Internal third parties;</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">External third parties; or</span>
            </p>
            <p className="MsoListParagraphCxSpLast" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Third parties to whom we may choose to sell, transfer, or merge parts of
                    our business or our assets.
                </span>
            </p>
            <p className="MsoNormal" style={{ marginLeft: "18.0pt" }}>
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Alternatively, we may seek to acquire other businesses or merge with them.
                    If a change happens to our business, then the new owners may use your
                    personal data in the same way as set out in this privacy notice.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    We require all third parties to respect the security of your personal data
                    and to treat it in accordance with the law. We do not allow our
                    third-party service providers to use your personal data for their own
                    purposes and only permit them to process your personal data for specified
                    purposes and in accordance with our instructions.
                    <br />
                    <br />
                    <b>6. INTERNATIONAL TRANSFERS</b>
                </span>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">
                        <br />
                    </span>
                </b>
                <span lang="EN-GB">
                    Whenever we transfer your personal data out of the EEA, we ensure a
                    similar degree of protection is afforded to it by ensuring at least one of
                    the following safeguards is implemented:
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    We will only transfer your personal data to countries that have been
                    deemed to provide an adequate level of protection for personal data by the
                    European Commission. For further details, see European Commission:
                    Adequacy of the protection of personal data in non-EU countries.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Where we use certain service providers, we may use specific contracts
                    approved by the European Commission which give personal data the same
                    protection it has in Europe. For further details, see European Commission:
                    Model contracts for the transfer of personal data to third countries.
                </span>
            </p>
            <p className="MsoListParagraphCxSpLast" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Where we use providers based in the United States of America, we may
                    transfer data to them if they are part of the Privacy Shield which
                    requires them to provide similar protection to personal data shared
                    between the Europe and the United States of America. For further details,
                    see European Commission: EU-US Privacy Shield.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Please contact us at&nbsp;pm@regent-dental.co.uk&nbsp;if you want further
                    information on the specific mechanism used by us when transferring your
                    personal data out of the EEA.
                    <br />
                    <br />
                    <b>7. DATA SECURITY</b>
                </span>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">
                        <br />
                    </span>
                </b>
                <span lang="EN-GB">
                    We have put in place appropriate security measures to prevent your
                    personal data from being accidentally lost, used or accessed in an
                    unauthorised way, altered or disclosed. In addition, we limit access to
                    your personal data to those employees, agents, contractors and other third
                    parties who have a business need to know. They will only process your
                    personal data on our instructions and they are subject to a duty of
                    confidentiality.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    We have put in place procedures to deal with any suspected personal data
                    breach and will notify you and any applicable regulator of a breach where
                    we are legally required to do so.
                    <br />
                    <br />
                    <b>
                        8. DATA RETENTION
                        <br />
                        ‍<br />
                        8.1 How long will you use my personal data for?
                        <br />
                    </b>
                    We will only retain your personal data for as long as necessary to fulfil
                    the purposes we collected it for, including for the purposes of satisfying
                    any legal, accounting, or reporting requirements.
                    <br />
                    To determine the appropriate retention period for personal data, we
                    consider the amount, nature, and sensitivity of the personal data, the
                    potential risk of harm from unauthorised use or disclosure of your
                    personal data, the purposes for which we process your personal data and
                    whether we can achieve those purposes through other means, and the
                    applicable legal requirements.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    In some circumstances you can ask us to delete your data: see request
                    erasure below for further information.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    In some circumstances we may anonymise your personal data (so that it can
                    no longer be associated with you) for research or statistical purposes in
                    which case we may use this information indefinitely without further notice
                    to you.
                    <br />
                    <br />
                    <b>
                        9. YOUR LEGAL RIGHTS
                        <br />
                    </b>
                    Under certain circumstances, you have rights under data protection laws in
                    relation to your personal data. Please click on Your legal rights below to
                    find out more about these rights:
                </span>
            </p>
            <p className="MsoListParagraphCxSpFirst" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB"> Request access to your personal data.</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">Request correction of your personal data.</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">Request erasure of your personal data.</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">Object to processing of your personal data.</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Request restriction of processing your personal data.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Right to withdraw consent.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: "0cm" }}>
                <span lang="EN-GB">
                    If you wish to exercise any of the rights set out above, please contact us
                    at&nbsp;pm@regent-dental.co.uk.
                    <br />
                    <br />
                    <b>
                        9.1 No fee usually required
                        <br />
                    </b>
                    You will not have to pay a fee to access your personal data (or to
                    exercise any of the other rights). However, we may charge a reasonable fee
                    if your request is clearly unfounded, repetitive or excessive.
                    Alternatively, we may refuse to comply with your request in these
                    circumstances.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: "0cm" }}>
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoListParagraphCxSpLast" style={{ marginLeft: "0cm" }}>
                <b>
                    <span lang="EN-GB">
                        9.2 What we may need from you
                        <br />
                    </span>
                </b>
                <span lang="EN-GB">
                    We may need to request specific information from you to help us confirm
                    your identity and ensure your right to access your personal data (or to
                    exercise any of your other rights). This is a security measure to ensure
                    that personal data is not disclosed to any person who has no right to
                    receive it. We may also contact you to ask you for further information in
                    relation to your request to speed up our response.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">
                        9.3 Time limit to respond
                        <br />
                    </span>
                </b>
                <span lang="EN-GB">
                    We try to respond to all legitimate requests within one month.
                    Occasionally it may take us longer than a month if your request is
                    particularly complex or you have made a number of requests. In this case,
                    we will notify you and keep you updated.
                    <br />
                    <br />
                    <b>
                        10. GLOSSARY
                        <br />
                        ‍<br />
                        10.1 Lawful basis
                        <br />
                    </b>
                    Legitimate interest means the interest of our business in conducting and
                    managing our business to enable us to give you the best service/product
                    and the best and most secure experience. We make sure we consider and
                    balance any potential impact on you (both positive and negative) and your
                    rights before we process your personal data for our legitimate interests.
                    We do not use your personal data for activities where our interests are
                    overridden by the impact on you (unless we have your consent or are
                    otherwise required or permitted to by law). You can obtain further
                    information about how we assess our legitimate interests against any
                    potential impact on you in respect of specific activities by contacting us
                    at&nbsp;pm@regent-dental.co.uk.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    Performance of contract means processing your data where it is necessary
                    for the performance of a contract to which you are a party or to take
                    steps at your request before entering into such a contract. Comply with a
                    legal or regulatory obligation means processing your personal data where
                    it is necessary for compliance with a legal or regulatory obligation that
                    we are subject to.
                    <br />
                    <br />
                    <b>
                        10.2 Third parties
                        <br />
                    </b>
                    Internal third parties
                    <br />
                    Other companies in the business acting as joint controllers or processors,
                    who are based in the EU and provide IT and system administration services
                    and leadership reporting.
                </span>
            </p>
            <p className="MsoListParagraphCxSpFirst">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: "0cm" }}>
                <span lang="EN-GB">External third parties</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Service providers acting as processors based in the United Kingdom who
                    provide IT and system administration services.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    Professional advisers acting as processors or joint controllers including
                    lawyers, bankers, auditors and insurers based in the United Kingdom who
                    provide consultancy, banking, legal, insurance and accounting services.
                </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "-18.0pt" }}>
                <span lang="EN-GB" style={{ fontFamily: "Symbol" }}>
                    ·
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <span dir="LTR" />
                <span lang="EN-GB">
                    HM Revenue &amp; Customs, regulators and other authorities acting as
                    processors or joint controllers based in the United Kingdom who require
                    reporting of processing activities in certain circumstances.
                </span>
            </p>
            <p className="MsoListParagraphCxSpLast">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">
                        11. YOUR LEGAL RIGHTS
                        <br />
                    </span>
                </b>
                <span lang="EN-GB">
                    You have the right to:
                    <br />
                    Request access to your personal data (commonly known as a “data subject
                    access request”). This enables you to receive a copy of the personal data
                    we hold about you and to check that we are lawfully processing it.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Request correction of the personal data that we hold about you. This
                    enables you to have any incomplete or inaccurate data we hold about you
                    corrected, though we may need to verify the accuracy of the new data you
                    provide to us.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Request erasure of your personal data. This enables you to ask us to
                    delete or remove personal data where there is no good reason for us
                    continuing to process it. You also have the right to ask us to delete or
                    remove your personal data where you have successfully exercised your right
                    to object to processing (see below), where we may have processed your
                    information unlawfully or where we are required to erase your personal
                    data to comply with local law. Note, however, that we may not always be
                    able to comply with your request of erasure for specific legal reasons
                    which will be notified to you, if applicable, at the time of your request.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Object to processing of your personal data where we are relying on a
                    legitimate interest (or those of a third party) and there is something
                    about your particular situation which makes you want to object to
                    processing on this ground as you feel it impacts on your fundamental
                    rights and freedoms. You also have the right to object where we are
                    processing your personal data for direct marketing purposes. In some
                    cases, we may demonstrate that we have compelling legitimate grounds to
                    process your information which override your rights and freedoms.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Request restriction of processing of your personal data. This enables you
                    to ask us to suspend the processing of your personal data in the following
                    scenarios: (a) if you want us to establish the data’s accuracy; (b) where
                    our use of the data is unlawful but you do not want us to erase it; (c)
                    where you need us to hold the data even if we no longer require it as you
                    need it to establish, exercise or defend legal claims; or (d) you have
                    objected to our use of your data but we need to verify whether we have
                    overriding legitimate grounds to use it.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Withdraw consent at any time where we are relying on consent to process
                    your{" "}
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    personal data. However, this will not affect the lawfulness of any
                    processing carried out before you withdraw your consent. If you withdraw
                    your consent, we may not be able to provide certain products or services
                    to you. We will advise you if this is the case at the time you withdraw
                    your consent.
                    <br />
                    <br />
                    <b>12. COOKIES NOTICE</b>
                </span>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">
                        <br />
                    </span>
                </b>
                <span lang="EN-GB">
                    This Cookies notice applies to our website www.regent-dental.co.uk all
                    branded pages on third party platforms and applications accessed or used
                    through such websites or third-party platforms (the “Sites”), which are
                    operated by or on behalf of Regent Dental (“we”, “us”, “our”).
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    BY USING OUR SITES, YOU ARE CONSENTING TO OUR USE OF COOKIES IN ACCORDANCE
                    WITH THIS COOKIES NOTICE. What is a cookie? A cookie is a small text file
                    stored by your web browser on your computer or mobile device. Some cookies
                    are essential for the Sites to work and help us provide you with relevant
                    information. They also allow us to recognise your computer (but not
                    specifically who is using it) when you access our Sites and to improve the
                    usability and performance of our Sites.
                    <br />
                    What do we use cookies for? We use cookies to compile anonymous,
                    aggregated statistics that allow us to understand how people use our sites
                    and to help us improve their structure and content. We cannot identify you
                    personally from this information.
                    <br />
                    Cookie Name/Title Purpose
                    <br />
                    Google Analytics (__utma,
                    <br />
                    __utmb, __utmc, __utmz) Google set a number of cookies on pages which
                    include a Google Map. These cookies measure the number and behaviour of
                    Google Maps users.
                    <br />
                    Google Maps (SID, SAPISID, APISID, SSID, HSID, NID, PREF) These cookies
                    are used to collect information about how visitors use our Sites. The
                    information includes the time of the current visit, whether you have been
                    to the Site before and what website referred you here. We use this
                    information to estimate our audience size and usage pattern, so that we
                    can improve our Sites by understanding how people use them. Click here for
                    further information from Google on Google Analytics and your privacy.
                    <br />
                    Turning off and deleting cookies. You may refuse to accept cookies by
                    activating the setting on your browser which allows you to refuse the
                    setting of cookies. If you select this setting you may be unable to access
                    certain parts of our sites. Unless you have adjusted your browser settings
                    so that it will refuse cookies, our system will issue cookies when you
                    access our sites. If you use different devices to view and access our
                    sites (for example, your computer, smart phone, tablet etc.) you will need
                    to ensure that each browser on each device is adjusted to suit your cookie
                    preferences.
                    <br />
                    If you disable the cookies that we use this may impact your experience
                    while on the sites.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    <br />
                    Changes to this notice. We may revise this cookies notice from time to
                    time. Any changes we may make to our cookies notice in the future will be
                    posted on this page.
                    <br />
                    <br />
                </span>
            </p>
            <p className="MsoNormal">
                <b>
                    <span lang="EN-GB">Contact us</span>
                </b>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">
                    Questions, comments and requests regarding this cookies notice are
                    welcomed. Please contact us at&nbsp;pm@regent-dental.co.uk.
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
        </div>

    );
};

export default SmileSection;
