import React, { useEffect, useRef, useState } from 'react';
import starImage from '../../assets/img/64f7673674dcbc884c9fa4f9_swish-floss-image-p-800.webp.png';
import 'animate.css'; // Make sure to import Animate.css

const HeroSection2 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set to true when the section is visible
            } else {
                setIsVisible(false); // Set to false when the section is not visible
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1 // Adjust threshold as needed
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref}>
            <div className="row hero-2 bg-theme">
                <div className="col-lg-6">
                    <div className="d-flex justify-content-center align-items-center p-5">
                        <img
                            className={`ms-5 img-fluid shadow--golden-1 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                            src={starImage}
                            alt="Star Image"
                            style={{ maxHeight: '650px', borderRadius: '608.46px 608.46px 0px 0px' }}
                        />
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div className="p-5 text-light mt-0 mt-lg-5">
                        <p className={` ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            Dental care you'll have
                        </p>
                        <h1 className={` ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            Helping you achieve <br />
                            <span className="text-golden">your dream smile</span>
                        </h1>
                        <div className="pe-0 pe-md-5">
                            <p className={` ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                Our goal as dentists is to establish lifelong connections with our patients. We strive to create healthy smiles and change the way you think about seeing the dentist.
                            </p>
                            <p className={` ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                At our dental practice, we are dedicated to preventing, diagnosing, and treating oral health issues. We prioritise your comfort, utilise the newest dental technology, and provide transparent patient care.
                            </p>
                            <p className={` ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                At Regent, you'll receive comprehensive and non-judgmental dental services that are both exceptional and affordable.
                            </p>
                        </div>
                        <a href="/contact-us" className={`btn btn-golden shadow-lg ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            Book an Appointment
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection2;
