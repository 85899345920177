// src/components/HeroSection.js
import React, { useEffect, useRef, useState } from 'react';
import starImage from '../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png';
import 'animate.css';
import Hero from '../../assets/img/hero.jpg';

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false); // Set to false when the section is not visible
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className='container-fluid d-flex justify-content-center align-items-center' 
      style={{
        height: '100vh',
        backgroundImage: `url(${Hero})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <div className="row hero-1 align-items-center px-5 text-white" ref={sectionRef}>
        <div className="col-lg-12">
          <div className="d-flex ps-0 ps-md-5 justify-content-center align-items-center">
            <div className="p-4 p-md-5 mt-5">
              <img src={starImage} alt="Stars" height="20" />
              <div className='d-none d-lg-block'>
                <h1
                  className={`text-white fs-70 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                  Seeing the <br />
                  dentist just <br />
                  <span className="text-golden">got cooler</span>
                </h1>
                <p
                  className={`pe-0 fs-16 pe-md-5 mt-4 w-50 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                  Welcome to Regent Dental, where seeing the dentist is something you actually look forward to.
                  We are dedicated to helping you achieve your healthiest smile, delivering compassionate dental
                  care and an extraordinary dental experience.
                </p>
              </div>
              <div className='d-lg-none'>
                <h1
                  className={`text-white fs-44 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                  Seeing the <br />
                  dentist just <br />
                  <span className="text-golden">got cooler</span>
                </h1>
                <p
                  className={`pe-0 fs-14 pe-md-5 mt-4 w-100 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                  Welcome to Regent Dental, where seeing the dentist is something you actually look forward to.
                  We are dedicated to helping you achieve your healthiest smile, delivering compassionate dental
                  care and an extraordinary dental experience.
                </p>
              </div>
              <div className="text-center">
                <a
                  href="/contact-us"
                  className={`btn btn-theme mt-3 shadow ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                  Book an Appointment
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
