import React, { useEffect, useRef, useState } from 'react';
import image1 from '../../assets/img/652ee7990fa02b5381ecddcf_preventative-dentistry-image.webp.png';
import image2 from '../../assets/img/652ee79007d8d95c7035c5ee_restorative-dentistry-image.webp.png';
import image3 from '../../assets/img/652ee78517e69191195816ee_emergency-appointment-image-p-500.webp.png';
import image4 from '../../assets/img/64e3bdf9bb5734e1e85f05e0_invisalign-p-500.webp.png';
import image5 from '../../assets/img/652ee7740941ed09c5efc8e1_cosmetic-dentistry-image.webp.png';
import image6 from '../../assets/img/652ee76ddb2d5fa59b3b9966_teeth-whitening-image-p-500.webp.png';
import 'animate.css'; // Ensure Animate.css is imported

const TreatmentOptions = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set to true when the section is visible
            } else {
                setIsVisible(false); // Set to false when the section is not visible
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1 // Adjust threshold as needed
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref} className="row align-items-center p-5">
            <div className="col-lg-6">
                <img src="https://kingtondental.co.uk/assets/lauren.jpeg" className={` img-fluid rounded-4 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}></img>
            </div>
            <div className="col-lg-6">
                <div className="container d-flex justify-content-start mt-5">
                    <div className="text-start">
                        <p className={` ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            Treatment options
                        </p>
                        <h1 className={` text-theme ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            I could not be more pleased with the results of my Invisalign.<br></br>
                            <small className='text-golden fs-18'>Lauren</small>
                        </h1>
                        <p className={`p-0 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                        Diana was amazing from start to finish and nothing was ever too much even when I had lots going on with a wedding and baby in the middle of my treatment.
                        <br></br><br></br>
                        So thankful for her and her amazing team and would recommend her to anyone. Thank you for all you have done and being so kind, considerate and caring throughout ☺️
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TreatmentOptions;
