import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/3.png'; // Adjust the path as needed

const Navbar = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid px-4">
                    <Link className="navbar-brand animate__animated animate__zoomIn" to="/">
                        <img src={logo} alt="Logo" height="60" />
                    </Link>
                    <button
                        className="navbar-toggler d-lg-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsibleNavId"
                        aria-controls="collapsibleNavId"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon fs-12"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavId">
                        <ul className="navbar-nav ms-auto me-auto mt-2 mt-lg-0">
                            <li className="nav-item animate__animated animate__zoomIn dropdown">
                                <Link
                                    className="nav-link px-3 dropdown-toggle"
                                    to="#"
                                    id="dropdownId1"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    About Us
                                </Link>
                                <div className="dropdown-menu  border-0 shadow" aria-labelledby="dropdownId1" style={{ minWidth: '250px' }}>
                                    <Link className="dropdown-item p-lg-3 " to="/team">
                                        Meet The Team
                                    </Link>
                                    <Link className="dropdown-item p-lg-3 " to="/our-technology">
                                        Our Technology
                                    </Link>
                                </div>
                            </li>

                            <li className="nav-item animate__animated animate__zoomIn dropdown">
                                <Link className="nav-link px-3 dropdown-toggle" to="#" id="dropdownId1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Treatments
                                </Link>
                                <div className="dropdown-menu  border-0 shadow" aria-labelledby="dropdownId1" style={{ minWidth: '250px' }}>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/general-dentistry">
                                        General Dentistry
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/invisalign">
                                        Invisalign<sup>&#174;</sup>
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/niTime-aligners">
                                        NiTime Aligners
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/dental-implants">
                                        Dental Implants
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/all-on-4-dental-implants">
                                        All on 4 Dental Implants
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/smile-fast-composite-bonding">
                                        Composite Bonding
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/porcelain-veneers">
                                        Porcelain Veneers
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/teeth-whitening">
                                        Teeth Whitening
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/restorative-dentistry">
                                        Restorative Dentistry
                                    </Link>
                                    <Link className="dropdown-item p-lg-3" to="/root-canal">
                                        Root Canal Treatment
                                    </Link>
                                </div>
                            </li>

                            <li className="nav-item animate__animated animate__zoomIn dropdown">
                                <Link className="nav-link px-3" to="/patient-stories">
                                    Patient Stories
                                </Link>
                            </li>

                            <li className="nav-item animate__animated animate__zoomIn dropdown">
                                <Link className="nav-link px-3 dropdown-toggle" to="#" id="dropdownId1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Fees and Finance
                                </Link>
                                <div className="dropdown-menu  border-0 shadow" aria-labelledby="dropdownId1" style={{ width: '250px' }}>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/fee">
                                        Fees
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="/dental-plans">
                                        Dental Plans
                                    </Link>
                                    <Link className="dropdown-item p-lg-3  border-bottom" to="https://lead.tabeo.co.uk/regent-dental/finance" target="_blank">
                                        Finance Options
                                    </Link>
                                </div>
                            </li>

                            <li className="nav-item animate__animated animate__zoomIn dropdown">
                            </li>
                        </ul>
                        <div className="d-flex my-2 my-lg-0 me-1">
                            <Link to="/contact-us" className="fs-14 btn btn-theme-nav animate__animated animate__zoomIn">
                                Book an Appointment
                            </Link>
                        </div>
                        <div className="d-flex my-2 my-lg-0 dropdown">
                            <Link
                                className="btn-golden-nav nav-link px-3 dropdown-toggle animate__animated animate__zoomIn"
                                to="#"
                                id="dropdownId1"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Referrals
                            </Link>
                            <div
                                className="dropdown-menu border-0 shadow rounded"
                                aria-labelledby="dropdownId1"
                                style={{ minWidth: '200px', marginTop: '3.0rem' }} // Adjust margin to position the dropdown correctly
                            >
                                <Link className="dropdown-item p-3 border-bottom" to="/referral/dental-implant">
                                    Dental Implant
                                </Link>
                                <Link className="dropdown-item p-3" to="/referral/cbct">
                                    CBCT
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
