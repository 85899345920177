import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

const GeneralDentistryProcess = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FFFBF1', minHeight: '95vh' }}>
            <div className='container'>
                <div className="text-center">
                    <h1 className={`mt-5 animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        How General Dentistry <br /> Works A Simple, Painless Process
                    </h1>
                    <p className={`mx-auto w-75 animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                    We use the latest CBCT scanning technology to digitally plan your treatment so that we can place multiple implants in a 
                    single surgery so that you can leave on the day with a temporary set of teeth whilst the healing takes place.
                    </p>
                </div>

                <div className="p-4">
                    <div className="row justify-content-center align-items-stretch">
                        {[
                            {
                                number: 1,
                                title: "Consultation and Planning",
                                description: "A thorough dental examination is conducted to assess your suitability."
                            },
                            {
                                number: 2,
                                title: "Implant Placement",
                                description: "Up to 6  implants are surgically inserted into the jawbone, serving as the anchor for the new teeth."
                            },
                            {
                                number: 3,
                                title: "Immediate Prosthesis",
                                description: "Attaching a temporary full arch prosthesis to the implants on the same day, providing instant results."
                            },
                            {
                                number: 4,
                                title: "Healing Period",
                                description: "Allowing time for the implants to integrate with the bone while wearing the temporary teeth."
                            },
                            {
                                number: 5,
                                title: "Final Prosthesis",
                                description: "We'll replace the temporary prosthesis with a custom-made permanent one for a long-lasting, natural-looking smile."
                            }
                        ].map((service, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 animate__animated ${isVisible ? 'animate__fadeInUp' : 'animate__fadeOut'}`}>
                                    <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3">
                                        {service.number}
                                    </div>
                                    <div className="fs-18 fw-600">{service.title}</div>
                                    <p className="fs-14">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistryProcess;
