import React, { useEffect, useRef, useState } from 'react';
import vectorImg from '../../assets/img/Vector.png';
import teamImg from '../../assets/img/64e76a74015a878b61b17cf0_swish-team-image-p-800.webp.png';
import 'animate.css'; // Ensure Animate.css is imported

const RegentDentalExperience = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set to true when the section is visible
            } else {
                setIsVisible(false); // Set to false when the section is not visible
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1 // Adjust threshold as needed
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref} className='container px-3'>
            <div className="row align-items-center mb-3">
                <div className="col-lg-6">
                    <div className="">
                        <div className={`d-flex justify-content-start align-items-center h-100 p-5 ${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>
                            <div>
                                <h1 className={`text-theme ${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>
                                    You deserve the <span className="text-golden">best dental care</span>
                                </h1>
                                <p className={`${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>
                                    Our dedicated team embodies a culture of compassion and support. We are dedicated to making your dental experience comfortable, empowering, and even fun – that’s right – we said it! Our team is here to help you feel at ease, providing transparent care on your journey to a healthy smile that you’ll love.
                                </p>
                                <p className={`${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>Here’s just a few things that keep our patients coming back:</p>
                            </div>
                        </div>
                        <div className="px-5">
                            <div className={`d-flex justify-content-start ${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>
                                <div className="me-3">
                                    <img src={vectorImg} alt="" height="15" />
                                </div>
                                <div className="fw-500">Expert care in an inviting atmosphere</div>
                            </div>
                            <div className={`d-flex justify-content-start ${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>
                                <div className="me-3">
                                    <img src={vectorImg} alt="" height="15" />
                                </div>
                                <div className="fw-500">Judgment-free dental care</div>
                            </div>
                            <div className={`d-flex justify-content-start ${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>
                                <div className="me-3">
                                    <img src={vectorImg} alt="" height="15" />
                                </div>
                                <div className="fw-500">Home-like amenities to make you comfortable</div>
                            </div>
                        </div>
                        <div className={`mt-4 mb-3 px-5 ${isVisible ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOut'}`}>
                            <a href="/team" className="btn btn-theme">Meet the Team</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <div className="p-5">
                        <img 
                            src={teamImg} 
                            className={`shadow-golden-2 img-fluid ${isVisible ? 'animate__animated animate__fadeInRight' : 'animate__animated animate__fadeOut'}`} 
                            style={{ borderRadius: '608.46px 608.46px 0px 0px' }} 
                            alt="Swish Team" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegentDentalExperience;
