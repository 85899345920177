import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

const GeneralDentistryProcess = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FFFBF1', minHeight: '95vh' }}>
            <div className='container'>
                <div className="text-center mt-5">
                    <h1 className={`animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                    Regent Dental's Approach to <br /> Smile Enhancement
                    </h1>
                    <p className={`w-75 mx-auto animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'} animate__delay-1s`}>
                        As a SmileFast Centre of Excellence, we combine the latest materials and technology to let you design your smile, test drive it in your mouth before giving you a predictable and aesthetic final result. 
                    </p>
                </div>

                <div className="p-4">
                    <div className="row justify-content-center align-items-stretch">
                        {[
                            {
                                number: 1,
                                title: "Consultation",
                                description: " We will begin with a  discussion about your goals and a dental examination before designing your new smile."
                            },
                            {
                                number: 2,
                                title: "Design",
                                description: "We will then design your smile and show you a photo of what your smile will look like."
                            },
                            {
                                number: 3,
                                title: "Trial Smile",
                                description: "We will transfer the smile in to your mouth with temporary material so you can test drive it and make sure your happy."
                            },
                            {
                                number: 4,
                                title: "Fit",
                                description: "A stent is then used to apply the composite in your mouth and create your final smile."
                            },
                            {
                                number: 5,
                                title: "Polishing",
                                description: "The final step involves polishing the bonded areas to a natural, tooth-like sheen."
                            }
                        ].map((service, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 animate__animated ${isVisible ? 'animate__fadeInUp' : 'animate__fadeOut'}`}>
                                    <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3">
                                        {service.number}
                                    </div>
                                    <div className="fs-18 fw-600">{service.title}</div>
                                    <p className="fs-14">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistryProcess;
