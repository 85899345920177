import { Helmet } from 'react-helmet';
import Navbar from '../components/universal/navbar';
import Header from '../components/fee/feepageHero';
import FeeTables from '../components/fee/feeTables';
import Contact from '../components/universal/contact';
import Footer from '../components/universal/footer';


function Index() {
    return (
        <div>
            <Helmet>
                <title>Local Dentist in Ilkley, West Yorkshire - Fee Plans </title>
            </Helmet>
            <Navbar />
            <Header />
            <FeeTables />
            <Contact />
            <Footer />
        </div>
    );
}

export default Index;