import React, { useEffect, useState } from 'react';
import '../../assets/css/team.css'
import 'animate.css';
import placeholder from '../../assets/img/placeholder.png'
import drNamdeen from '../../assets/img/dr-nadeem.png'
import imaan from '../../assets/img/image.png'
import Naiz from '../../assets/img/naiz.jpg'
import naeem from '../../assets/img/naeem.jpg'

const ProfileSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Trigger animation on component mount
        setIsVisible(true);
    }, []);

    return (
        <section className="">
            <div className="container mt-5">

                <div>
                    <div className='text-center mb-5'>
                        <h1 className='text-theme'> Your <span className='text-golden'> Clinical Team</span></h1>
                    </div>
                    
                    {/* First Row */}
                    <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
                        <div className="col-md-4">
                            <img 
                                src={drNamdeen} 
                                alt="Doctor Image" 
                                className={`shadow--golden-1 img-fluid profile-image animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}
                            />
                        </div>
                        <div className="col-md-8">
                            <h2 className={`fw-bold animate__animated ${isVisible ? 'animate__fadeInRight' : ''} mt-4`}>
                                Dr. Nadeem Sabir
                            </h2>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                                BDS (Hons), MFDS RCS Ed <br></br>
                                <a href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=244093" target='_blank' className='text-black text-decoration-none'>GDC No 244093</a>
                            </p>
                            <h5 className={`text-golden animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                                PRINCIPAL & CLINICAL DIRECTOR
                            </h5>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-1s' : ''}`}>
                                Dr Nadeem Sabir graduated from the University of Manchester with Honours in 2013 before going on to obtain his Diploma of Membership of the Faculty of Dental Surgery (MFDS) from the Royal College of Surgeons Edinburgh.
                                <br></br>
                                <br></br>
                                He has trained under some of the most prominent and esteemed leaders in Implant Dentistry which has now led him to focus his practice solely on dental implants and complex surgical dentistry. He is experienced in treating nervous patients with intravenous sedation and provides this service for his patients when appropriate. Nadeem is involved in training the next generation of implant dentists as a mentor for SmileFast Implants.
                                <br></br>
                                <br></br>
                                Dr Sabir is a perfectionist and combines true professionalism with genuine warmth and informality, ensuring that even the most anxious patient is made to feel relaxed and reassured. He is renowned for being very approachable and highly empathetic; he will explain all treatment modalities and educate all his patients before starting treatment.
                            </p>
                    
                            {/* Fun Fact */}
                            <div className={`fun-fact animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-2s' : ''}`}>
                                <h6 className="fw-bold">Fun Fact</h6>
                                <p>Dr Nadeem is an avid explorer and enjoys spending his free time and hiking and exploring the natural beauty Yorkshire has to offer.</p>
                            </div>
                        </div>
                    </div>

                    {/* second Row */}
                    <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
                        
                        <div className="col-md-8 text-end">
                            <h2 className={`fw-bold animate__animated ${isVisible ? 'animate__fadeInRight' : ''} mt-4`}>
                                Naiz Khan
                            </h2>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                                BDS Brist,United Kingdom, 2005 <br></br> 
                            <a href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=101469" target='_blank' className='text-black text-decoration-none'>GDC No 101469</a>
                            </p>
                            <h5 className={`text-golden text-uppercase animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                                Cosmetic Dentist
                            </h5>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-1s' : ''}`}>
                                Dr Naiz Khan qualified from the University of Bristol in 2005 and has spent the last 15 years treating thousands of patients in Gloucester, Cheltenham, Banbury and Worcester.
                                <br></br>
                                <br></br>
                                With a keen interest in orthodontics, Dr Naiz has been providing teeth straightening solutions with Invisalign for nearly a decade now to treat a variety of orthodontic concerns including teeth that are crooked, crowded or spaced out. Dr Naiz is one of the UK’s leading providers of Invisalign treatments and is part of the Apex group of providers which recognises him as one of the top 1% of Invisalign providers in the country.
                                <br></br>
                                <br></br>
                                His aim is to create a long lasting, friendly, and professional relationship based on honesty and trust with every patient, so that they are at ease when receiving the very best care
                                <br></br>
                                <br></br>
                                Dr Naiz gets his job satisfaction from being able to combine the art and science of dentistry to help create smiles, which help increase confidence and self-esteem in his patients.
                                <br></br>
                                <br></br>
                                Dr Naiz spends his time outside of surgery with his young children and supporting other dentists with their clinical skills.
                            </p> 
                    
                            {/* Fun Fact */}
                            <div className={`fun-fact animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-2s' : ''}`}>
                                <h6 className="fw-bold">Fun Fact</h6>
                                <p>Dr. Dr Naiz is a Diamond Apex Invisalign Provider making him the top 1% of providers worldwide</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img 
                                src={Naiz}
                                alt="Doctor Image" 
                                className={`shadow-golden-2 img-fluid profile-image animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}
                            />
                        </div>
                    </div>
                    
                    {/* thisrd Row */}
                    <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
                    <div className="col-md-4">
                            <img 
                                src={imaan} 
                                alt="Imaan" 
                                className={`shadow--golden-1 img-fluid profile-image animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}
                            />
                        </div>
                        <div className="col-md-8 text-start">
                            <h2 className={`fw-bold animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}>
                                Dr. Imaan Khalid
                            </h2>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                                MChD BChD BSc Leeds 2020 <br></br> 
                                <a href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=290121" target='_blank' className='text-black text-decoration-none'>GDC No 290121</a>
                            </p>
                            <h5 className={`text-golden text-uppercase animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}>
                                Cosmetic Dentist
                            </h5>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInLeft animate__delay-1s' : ''}`}>
                            Imaan is a dedicated general dentist practicing in West Yorkshire. She earned her degree with Distinction from the University of Leeds in 2020 and was recognized as a finalist in the 2018 Future of Dentistry Awards.
                    
                            <br></br> <br></br>
                    
                            With a commitment to professional growth and a strong affiliation with the British Academy of Cosmetic Dentistry (BACD), Imaan has been an active member since 2017. Her engagement began as a student representative, and she was later invited to join the Young Membership Committee, Communication Committee and Trade Committee.
                    
                            <br></br> <br></br>
                    
                            Imaan's contributions extend beyond her practice. She serves as a Committee member and on the Board of Directors for the British Association of Private Dentistry.
                    
                            <br></br> <br></br>
                    
                            Imaan has an unwavering dedication and passion for dentistry and drives to continually elevate her skills.
                    
                            <br></br> <br></br>
                    
                            Imaan is particularly devoted to dental education, aiming to inspire and guide younger dentists to attain the exceptional standards she holds herself by holding further committee positions for Dentinal Tubules.
                    
                            <br></br> <br></br>
                    
                            Always focused on providing excellence in patient care and emphasizing minimally invasive ethical dentistry, Imaan is currently pursuing a Postgraduate Diploma in Advanced Aesthetics with Elevate Dentistry.
                            </p>
                    
                            {/* Fun Fact */}
                            <div className={`fun-fact animate__animated ${isVisible ? 'animate__fadeInLeft animate__delay-2s' : ''}`}>
                                <h6 className="fw-bold">Fun Fact</h6>
                                <p>Is a member of British Association of Cosmetic Dentists Board</p>
                            </div>
                        </div>
                    </div>
                    
                    {/* Fourth Row */}
                    <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
                        
                        <div className="col-md-8 text-end">
                            <h2 className={`fw-bold animate__animated ${isVisible ? 'animate__fadeInRight' : ''} mt-4`}>
                                Dr. Saira Asghar
                            </h2>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                            BSc (Sheffield 2008) <br></br> 
                            <a href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=153699" target='_blank' className='text-black text-decoration-none'>GDC No 153699</a>
                            </p>
                            <h5 className={`text-golden text-uppercase animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                            Cosmetic Dentist
                            </h5>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-1s' : ''}`}>
                            Saira qualified with her BDS degree in Dentistry from the university of Sheffield in 2008. She spent her first few years practising as a locum dentist throughout the UK before settling down in West Yorkshire in 2010. She is currently based at Keighley where she offers a wide range of treatments including whitening (using the Enlighten and Zoom systems), composite bonding and general dental care. She has a keen interest in skincare and is qualified to provide medical grade skin treatments using leading brands. Saira is diligent, attentive and caring and has a very gentle manner towards her patients. She believes the patient’s experience should always be an enjoyable and comfortable one. Outside of work, Saira enjoys baking, keeping active and enjoys exploring Yorkshires many beauty spots with her three young children.
                            </p>
                    
                            {/* Fun Fact */}
                            {/* <div className={`fun-fact animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-2s' : ''}`}>
                                <h6 className="fw-bold">Fun Fact</h6>
                                <p>Dr. Desai was named one of “Austin’s Best Dressed” by CultureMap!</p>
                            </div> */}
                        </div>
                        <div className="col-md-4">
                            <img 
                                src={placeholder}
                                alt="Doctor Image" 
                                className={`shadow-golden-2 img-fluid profile-image animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}
                            />
                        </div>

                    </div>

                    {/* fifth Row */}
                    <div className="row align-items-center mb-4 border-2 border-bottom p-lg-5 p-3">
                    <div className="col-md-4">
                            <img 
                                src={naeem} 
                                alt="Imaan" 
                                className={`shadow--golden-1 img-fluid profile-image animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}
                            />
                        </div>
                        <div className="col-md-8 text-start">
                            <h2 className={`fw-bold animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}>
                                Dr. Naeem
                            </h2>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                            BDS 2019 <br></br> 
                                <a href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=283591" target='_blank' className='text-black text-decoration-none'>GDC No 283591</a>
                            </p>
                            <h5 className={`text-golden text-uppercase animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}>
                                Cosmetic Dentist
                            </h5>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInLeft animate__delay-1s' : ''}`}>
                                Dr. Naeem is a compassionate and skilled general dentist dedicated to delivering exceptional patient care. A graduate of the University of Manchester in 2019, Dr Naeem brings extensive experience in oral surgery, having worked in a hospital setting where they handled complex soft and hard tissue cases.
                                <br/>
                                <br/>
                                With a passion for enhancing smiles, Dr. Naeem is also a highly trained cosmetic dentist, offering treatments such as composite bonding, porcelain veneers, teeth whitening, and Invisalign. Beyond aesthetics, Dr. Naeem is experienced in rehabilitating patients’ mouths, providing solutions for tooth wear and restoring broken-down teeth to their optimal health and function.
                                <br/>
                                <br/>
                                Dr. Naeem is committed to creating a comfortable, relaxing environment for patients and is especially skilled in treating those who may feel nervous or anxious about dental visits. Understanding each patient’s unique needs, Dr. Naeem ensures that every treatment is as stress-free and enjoyable as possible.
                            </p>
                    
                            {/* Fun Fact */}
                            <div className={`fun-fact animate__animated ${isVisible ? 'animate__fadeInLeft animate__delay-2s' : ''}`}>
                                <h6 className="fw-bold">Fun Fact</h6>
                                <p>Outside of the practice, Dr. Naeem enjoys spending quality time with family, playing football, and staying active at the gym.</p>
                            </div>
                        </div>
                    </div>

                </div>






                <div>
                    {/* <div className='text-center mb-5'>
                        <h1 className='text-theme'> Your <span className='text-golden'> Support Team</span></h1>
                    </div>
                    
                    
                    <div className="row mb-5 align-items-center border-2 border-bottom p-lg-5 p-3">
                        
                        <div className="col-md-4">
                            <img 
                                src={placeholder}
                                alt="Doctor Image" 
                                className={`shadow--golden-1 img-fluid profile-image animate__animated ${isVisible ? 'animate__fadeInLeft' : ''}`}
                            />
                        </div>
                        <div className="col-md-8 text-start">
                            <h2 className={`fw-bold animate__animated ${isVisible ? 'animate__fadeInRight' : ''} mt-4`}>
                                Julie Bastow
                            </h2>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                            BSc (Sheffield 2008) <br></br> 
                            <a href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=153699" target='_blank' className='text-black text-decoration-none'>GDC No 153699</a>
                            </p>
                            <h5 className={`text-golden text-uppercase animate__animated ${isVisible ? 'animate__fadeInRight' : ''}`}>
                            Practice Manager
                            </h5>
                            <p className={`animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-1s' : ''}`}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non venenatis arcu. In vestibulum ante in nisl volutpat tristique. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla egestas ex augue, vulputate pretium tortor sagittis sed. Phasellus dolor dui, posuere pretium scelerisque at, sagittis a justo. Phasellus ultrices tincidunt orci nec ornare. Curabitur quis erat elementum nisi facilisis malesuada ut eget erat. Sed blandit lobortis justo et convallis.
                                <br></br>
                                <br></br>
                                Vestibulum non elementum mi, vel congue magna. Ut in felis eget ligula consequat pretium. Donec at nisl condimentum, varius arcu at, blandit erat. Proin pharetra quam neque, et cursus turpis vestibulum sit amet. Nulla turpis metus, lacinia at sapien tempor, accumsan congue urna. Morbi feugiat fermentum massa, vitae porttitor mauris vehicula fermentum. Pellentesque in ex nisi. Nullam magna libero, ullamcorper eget blandit a, finibus sed arcu. In iaculis ex eget auctor placerat. Vivamus blandit, ex nec gravida faucibus, quam risus sodales mi, quis cursus mi quam et massa. Suspendisse convallis quis metus sit amet vestibulum. Maecenas pulvinar, lorem ut sodales condimentum, sem dui consectetur sem, vitae sodales ante nisi non velit. Nullam et arcu eu odio finibus feugiat ut sed arcu. Donec bibendum est et porttitor ornare.
                                </p> 
                    
                            
                            <div className={`fun-fact animate__animated ${isVisible ? 'animate__fadeInRight animate__delay-2s' : ''}`}>
                                <h6 className="fw-bold">Fun Fact</h6>
                                <p>Dr. Desai was named one of “Austin’s Best Dressed” by CultureMap!</p>
                            </div>
                        </div>
                        
                    </div> */}



                </div>


            </div>
        </section>
    );
};

export default ProfileSection;
