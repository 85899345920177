import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import docImage from '../../assets/img/doc-white.png'; // Import your image

const DentalCareSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null); // Reference to the section for intersection observer

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Trigger animation when in view
                } else {
                    setIsVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 } // Adjust threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="bg-theme">
            <div className="container">
                <div className="row align-items-center">

                    <div className={`col-md-6 mt-4 animate__animated ${isVisible ? 'animate__fadeInLeft' : 'animate__fadeOut'}`}>
                        <img src={docImage} className="img-fluid" alt="Dental Care" />
                    </div>

                    <div className={`col-md-6 text-light mt-4 animate__animated ${isVisible ? 'animate__fadeInRight' : 'animate__fadeOut'}`}>
                        <p>Dental Care You'll Love</p>

                        <h1 className="fs-48">Brightening Your World with a Radiant Smile</h1>

                        <p>
                            Teeth whitening is a cosmetic dental procedure aimed at removing stains and discolouration from teeth and improving
                            their colour through safe, dental-grade whitening agents. 
                            It's a popular and efficient way to combat the effects of ageing, diet, and lifestyle on the colour of your teeth.
                        </p>
                        <p>
                            Whilst there are many over-the-counter options available, professional whitening offers safer, 
                            more reliable, and longer-lasting results under the guidance of a dental professional. 
                            The whitening process is tailored to your individual needs, ensuring your teeth and gums remain
                            healthy throughout the treatment.
                        </p>

                        <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">Book an Appointment</a>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default DentalCareSection;
