import React, { useEffect, useState } from 'react';
import 'animate.css';
import Img1 from '../../assets/img/dental-tech.jpg'
import stars from '../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png'

const GeneralDentistry = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <section>
            <div 
                className="d-flex justify-content-start align-items-center" 
                style={{ 
                    backgroundImage: `url(${Img1})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    height: '80vh' 
                }}
            >
                <div className="container text-light text-start mt-5">
                    <img 
                        src={stars}
                        className={`img-fluid mb-2 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`} 
                        alt="" 
                    />
                    <h1 className={`fs-50 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                    Feel Great About Your
                    <br/> <span className='text-golden'>Smile And Your Dentist</span>
                    </h1>
                    <p className={`mt-3 fs-16 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                        Discover a newfound confidence in your health and smile.
                    </p>
                    <div className="d-flex justify-content-start">
                        <a href="/contact-us" className="btn-golden text-decoration-none border-0 mt-3 animate__animated animate__fadeIn" type="button">
                            Book a Consultation
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistry;
