// src/components/HeroSection.js
import React, { useEffect, useRef, useState } from 'react';
import 'animate.css';

import mission from '../../assets/img/mission.png';

const HeroSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false); // Set to false when the section is not visible
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className='container py-5'>
            <div ref={sectionRef}>
                <div>
                    <div className="row align-items-center">
                        <div className='col-lg-6 p-5'>
                            <h2 className={`fs-40 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                Modern Dental Care, Right in Your Community
                            </h2>
                            <p className={`mt-4 mb-5 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            At Regent Dental, we believe everyone deserves a confident, healthy smile. Our team is dedicated to providing comfortable, cutting-edge care designed around you. We’re here to make your dental journey seamless, convenient, and personalized.
                            </p>
                            <div className={`bg-black rounded-4 p-4 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <h3 className='fs-33 text-white fw-300'>Our Mission</h3>
                                <p className='text-white fs-16'>
                                Our mission at Regent Dental is to make every patient feel at home. From the latest dental technology to personalized treatment plans, we prioritize your comfort and health at every step. 
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <img src={mission} className={`img-fluid ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
