import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import teamImage from '../../assets/img/64e76a74015a878b61b17cf0_swish-team-image-p-800.webp.png'; // Adjust the path as necessary

const RegentDentalExperience = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="hero-3 d-flex justify-content-center align-items-center bg-theme text-light" style={{ minHeight: '85vh' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 px-lg-3 p-4">
                        <div>
                            <p className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                The Regent Dental Experience
                            </p>
                            <h1 className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                Aftercare and <span className="text-golden"> Support</span>
                            </h1>
                            <p className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                            The whole team at Regent Dental is dedicated to providing ongoing support throughout your 
                            treatment, ensuring you're confident and comfortable at every stage as well as making sure your teeth remain in place.
                            </p>
                        </div>
                        <div>
                            <h1 className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                Wake Up to a <span className="text-golden">Straighter Smile</span>
                            </h1>
                            <p className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                NiTime aligners offer the discrete and clear path to a straighter smile, improving your dental health and boosting your self-esteem.
                            </p>
                        </div>
                        <div>
                            <a href="/team" className={`btn btn-golden mt-5 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                Meet the Team
                            </a>
                        </div>
                    </div>

                    <div className="col-md-6 p-5">
                        <img
                            src={teamImage}
                            className={`img-fluid shadow-golden-2 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}
                            style={{ borderTopLeftRadius: '350px', borderTopRightRadius: '350px' }}
                            alt="Team"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegentDentalExperience;
