import { Helmet } from 'react-helmet';
import Navbar from '../components/universal/navbar';
import Header from '../components/porcelain-veneers/hero';
import WhatAndWhy from '../components/porcelain-veneers/what-and-why';
import Process from '../components/porcelain-veneers/process';
import AfterCare from '../components/porcelain-veneers/aftercare';
import CTA from '../components/porcelain-veneers/cta';
import Contact from '../components/universal/contact';
import Footer from '../components/universal/footer';


function Index() {
    return (
        <div>
            <Helmet>
                <title>Local Dentist in Ilkley, West Yorkshire - Porcelain Veneers </title>
            </Helmet>
            <Navbar />
            <Header />
            <WhatAndWhy />
            <Process />
            <AfterCare />
            <CTA />
            <Contact />
            <Footer />
        </div>
    );
}

export default Index;