import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

const GeneralDentistryProcess = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center p-2 mt-lg-0" style={{ backgroundColor: '#FFFBF1', minHeight: '95vh' }}>
            <div className='container'>
                <div className="text-center">
                    <h1 className={`mt-5 animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        How General Dentistry <br /> Works 
                        A Simple, Painless Process
                    </h1>
                    <p className={`w-100 w-sm-50 mx-auto animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        At Regent Dental we've invested in state-of-the-art technology and materials to ensure the highest quality restorations.
                        With personalised care, from the initial consultation to the final fitting, results are both functional and beautiful.
                    </p>
                </div>

                <div className="p-4">
                    <div className="row justify-content-center align-items-stretch">
                        {[
                            {
                                number: 1,
                                title: "Fillings",
                                description: "The use of tooth-coloured materials to seamlessly repair cavities and restore your tooth's integrity."
                            },
                            {
                                number: 2,
                                title: "Crowns",
                                description: "Custom-made crowns to cover and protect damaged or weakened teeth, restoring their shape and function."
                            },
                            {
                                number: 3,
                                title: "Bridges",
                                description: "The design and fit of bridges to close gaps left by missing teeth, improving your bite and smile."
                            },
                            {
                                number: 4,
                                title: "Onlays",
                                description: "These are like a smaller version of a crown, like a hat that sits on top of your tooth."
                            },
                            {
                                number: 5,
                                title: "Dentures",
                                description: "Whether partial or full, our dentures are crafted to provide a comfortable fit and a natural look."
                            }
                        ].map((service, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 animate__animated ${isVisible ? 'animate__fadeInUp' : 'animate__fadeOut'}`}>
                                    <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3">
                                        {service.number}
                                    </div>
                                    <div className="fs-18 fw-600">{service.title}</div>
                                    <p className="fs-14">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistryProcess;
