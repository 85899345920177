import { Helmet } from 'react-helmet';
import Navbar from '../components/universal/navbar';
import Header from '../components/privacy-policy/hero';
import PP from '../components/privacy-policy/content';
import CTA from '../components/porcelain-veneers/cta';
import Contact from '../components/universal/contact';
import Footer from '../components/universal/footer';


function Index() {
    return (
        <div>
            <Helmet>
                <title>Local Dentist in Ilkley, West Yorkshire - Privacy Policy </title>
            </Helmet>
            <Navbar />
            <Header />
            <PP />
            <CTA />
            <Contact />
            <Footer />
        </div>
    );
}

export default Index;