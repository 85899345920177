import { Helmet } from 'react-helmet';
import Navbar from '../components/universal/navbar';
import Header from '../components/team/teampageHero';
import Team from '../components/team/team';
import Partners from '../components/universal/partners';
import CTA from '../components/universal/cta';
import Contact from '../components/universal/contact';
import Footer from '../components/universal/footer';


function Index() {
    return (
        <div>
            <Helmet>
                <title>Local Dentist in Ilkley, West Yorkshire - Team </title>
            </Helmet>
            <Navbar />
            <Header />
            <Team />
            <Partners />
            <CTA />
            <Contact />
            <Footer />
        </div>
    );
}

export default Index;