import { Helmet } from 'react-helmet';
import Navbar from '../components/universal/navbar';
import Hero from '../components/technlogoy/hero.js';
import Tech from '../components/technlogoy/tech.js';
import Partners from '../components/universal/partners.js';
import CTA from '../components/universal/cta';
import Contact from '../components/universal/contact';
import Footer from '../components/universal/footer';


function Index() {
    return (
        <div>
            <Helmet>
                <title>Local Dentist in Ilkley, West Yorkshire - Technology </title>
            </Helmet>
            <Navbar />
            <Hero />
            <Tech />
            <Partners />
            <CTA />
            <Contact />
            <Footer />
        </div>
    );
}

export default Index;