import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import docImage from '../../assets/img/doc-white.png'; // Import your image

const DentalCareSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null); // Reference to the section for intersection observer

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Trigger animation when in view
                } else {
                    setIsVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 } // Adjust threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="bg-theme">
            <div className="container">
                <div className="row align-items-center">

                    <div className={`col-md-6 mt-4 animate__animated ${isVisible ? 'animate__fadeInLeft' : 'animate__fadeOut'}`}>
                        <img src={docImage} className="img-fluid" alt="Dental Care" />
                    </div>

                    <div className={`col-md-6 text-light mt-4 animate__animated ${isVisible ? 'animate__fadeInRight' : 'animate__fadeOut'}`}>
                        <p>Dental Care You'll Love</p>

                        <h1 className="fs-48">Rejuvenating Your Smile with Expert Care</h1>

                        <p>
                            Restorative dentistry encompasses various dental treatments designed to repair or replace teeth. This includes fillings,
                            crowns, bridges and dentures, which all are designed to restore both the function and appearance of your teeth.
                        </p>
                        <p>
                            Maintaining the integrity of your mouth is crucial not just for aesthetics, but also for your overall health. Restorative 
                            procedures help to prevent further decay, support proper chewing and speaking, and maintain the structure of your jaw and face.
                        </p>

                        <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">Book an Appointment</a>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default DentalCareSection;
