import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

import icon1 from '../../assets/img/Frame.png';
import icon2 from '../../assets/img/orthodontic 1.png';
import icon3 from '../../assets/img/Frame-11.png';
import icon4 from '../../assets/img/Group...png';

const DiscreetBenefits = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    const benefits = [
        {
            number: icon1,
            title: "Bespoke Smiles you Test Drive",
            description: "Everyone’s idea about what makes a perfect smile is different because let's face it - everyone's unique. We will work with you to understand your goals and deliver a bespoke design that compliments you.",
        },
        {
            number: icon2,
            title: "Smile Like you Mean It",
            description: "Improving your smile is a natural confidence boost. We’ll tackle the specific issues you dislike most, giving self-esteem and confidence a priceless boost. ",
        },
        {
            number: icon3,
            title: "Simplicity",
            description: "The real beauty of composite bonding is that it’s straightforward. We simply apply a resin to sculpt your teeth. This quick procedure has no downtime, so you can enjoy your new smile immediately. ",
        },
        {
            number: icon4,
            title: "Long Lasting Results",
            description: "With the proper care, attention and regular maintenance visits, composite smile makeovers can provide years of confident smiles at a fraction of the price of porcelain. ",
        },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ minHeight: '95vh' }}>
            <div>
                <div className="text-center mb-5 mt-5">
                    <h1 className={`animate__animated ${isVisible ? 'animate__fadeIn' : ''}`}>
                        Discreet Benefits
                    </h1>
                </div>

                <div className="container mt-5 align-items-stretch">
                    <div className="row justify-content-center">
                        {benefits.map((benefit, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 text-center d-flex justify-content-center animate__animated ${isVisible ? 'animate__fadeInUp' : ''}`} style={{ backgroundColor: '#F3F4F6' }}>
                                    <div className="card-body">
                                        <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 mb-2 d-flex justify-content-center align-items-center mx-auto">
                                            <img src={benefit.number} className='img-fluid'></img>
                                        </div>
                                        <div className="fs-18 fw-600">{benefit.title}</div>
                                        <p className="fs-14">{benefit.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DiscreetBenefits;
