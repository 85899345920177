// src/components/SmileSection.js
import React, { useEffect, useRef, useState } from 'react';
import Hero from '../../assets/img/dentistry-team-office.jpg';
import 'animate.css';

const SmileSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false); // Set to false when the section is not visible
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                backgroundImage: `url(${Hero})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                height: '50vh',
            }}
            ref={sectionRef}
        >
            <div className="text-light text-center mt-5">
                <h1
                    className={`fs-60 text-white ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}
                >
                    Complaints Policy
                </h1>
            </div>
        </div>
    );
};

export default SmileSection;
