import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import '../../assets/css/team.css';
import 'animate.css';
import p1 from '../../assets/img/partners/1.jpg';
import p2 from '../../assets/img/partners/2.png';
import p3 from '../../assets/img/partners/3.png';
import p4 from '../../assets/img/partners/4.png';
import p5 from '../../assets/img/partners/5.png';
import p6 from '../../assets/img/partners/6.png';
import p7 from '../../assets/img/partners/7.png';
import p8 from '../../assets/img/partners/8.png';

const ProfileSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <section>
            {/* Partner Logos Section */}
            <div className='container mt-5 mb-5'>
                <div className='text-center mb-5'>
                    <h1 className='text-theme'> The <span className='text-golden'>Partners</span> We Work With</h1>
                </div>

                <Swiper
                    slidesPerView={4}
                    spaceBetween={70}
                    loop={true}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src={p1} height="50" alt="Partner 1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={p2} height="50" alt="Partner 2" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={p3} height="50" alt="Partner 3" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={p4} height="50" alt="Partner 4" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={p5} height="50" alt="Partner 5" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={p6} height="50" alt="Partner 6" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={p7} height="50" alt="Partner 7" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={p8} height="50" alt="Partner 8" />
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
};

export default ProfileSection;
