import React, { useEffect, useRef, useState } from 'react';
import 'animate.css'; // Ensure Animate.css is imported

const BookingCTA = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set to true when the section is visible
            } else {
                setIsVisible(false); // Set to false when the section is not visible
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1 // Adjust threshold as needed
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref} className="container-fluid bg-theme">
            <div className="row align-items-center justify-content-center" >
                <div className="cta-background d-flex justify-content-center align-items-center text-center w-50" style={{minHeight: '500px'}}>
                    <div className='px-0 px-lg-5'>
                        <h1 className={`text-golden mt-5 fs-40 ${isVisible ? 'animate__animated animate__bounceIn' : 'animate__animated animate__bounceOut'}`}>
                            Ready to book your appointment?
                        </h1>
                        <p className={`text-light px-0 px-md-5 mt-5 fs-18 ${isVisible ? 'animate__animated animate__bounceIn' : 'animate__animated animate__bounceOut'}`}>
                            Ready to transform your smile with composite bonding? Get in touch to book an appointment and take the first step towards a flawless smile.
                        </p>
                        <div className="row gap-0 mb-3 d-flex">
                            <div className='col-md-12 mb-3 d-flex justify-content-center'>
                                <a href="/contact-us" className={`btn btn-theme ${isVisible ? 'animate__animated animate__bounceIn' : 'animate__animated animate__bounceOut'}`}>
                                    Book an Appointment
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingCTA;
