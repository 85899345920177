import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

const GeneralDentistryProcess = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FFFBF1', minHeight: '95vh' }}>
            <div className='container'>
                <div className="text-center mt-5">
                    <h1 className={`animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        Regent Dental <br /> Approach to 
                        Your Smile Makeover
                    </h1>
                    <p className={`w-75 mx-auto animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'} animate__delay-1s`}>
                    We combine the science of dentistry with an artistic eye to design veneers that complement your unique 
                    features and personal aesthetic. Using advanced techniques, we ensure a seamless fit and an impeccable look.
                    </p>
                </div>

                <div className="p-4">
                    <div className="row justify-content-center align-items-stretch">
                        {[
                            {
                                number: 1,
                                title: "Consultation",
                                description: "It starts with a conversation about your aspirations for your smile and a comprehensive evaluation of your dental health."
                            },
                            {
                                number: 2,
                                title: "Design",
                                description: "We will scan your teeth and work with you to choose the right shade and shape for your veneers."
                            },
                            {
                                number: 3,
                                title: "Preparation",
                                description: "A minimal amount of tooth enamel is gently shaped to accommodate the veneers."
                            },
                            {
                                number: 4,
                                title: "Fitting",
                                description: " Once crafted, your veneers are carefully bonded to your teeth, instantly enhancing your smile."
                            },
                            {
                                number: 5,
                                title: "Reveal",
                                description: "The final step is the most rewarding—unveiling your transformed, radiant smile."
                            }
                        ].map((service, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 animate__animated ${isVisible ? 'animate__fadeInUp' : 'animate__fadeOut'}`}>
                                    <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3">
                                        {service.number}
                                    </div>
                                    <div className="fs-18 fw-600">{service.title}</div>
                                    <p className="fs-14">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistryProcess;
