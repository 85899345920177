import { Helmet } from 'react-helmet';
import Navbar from '../components/universal/navbar';
import Header from '../components/contact-us/hero';
import ContactForm from '../components/contact-us/contact-form';
import Footer from '../components/universal/footer';


function Index() {
    return (
        <div>
            <Helmet>
                <title>Local Dentist in Ilkley, West Yorkshire - Contact Us </title>
            </Helmet>
            <Navbar />
            <Header />
            <ContactForm />
            <Footer />
        </div>
    );
}

export default Index;