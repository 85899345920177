import React, { useEffect, useState } from 'react';
import 'animate.css';
import Img1 from '../../assets/img/Rectangle-15.png'
import stars from '../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png'

const GeneralDentistry = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <section>
            <div 
                className="d-flex justify-content-start align-items-center" 
                style={{ 
                    backgroundImage: `url(${Img1})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    height: '100vh' 
                }}
            >
                <div className="container text-light text-start">
                    <img 
                        src={stars}
                        className={`img-fluid mb-2 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`} 
                        alt="" 
                    />
                    <h1 className={`fs-60 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                        Restorative Dentistry
                    </h1>
                    <p className={`mt-3 fs-14 w-100 w-lg-50 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                        Regent Dental's commitment to restorative dentistry has driven our investment in the latest technology
                        to offer the best solutions to restore your teeth's function, comfort, and aesthetics. Whether it's 
                        replacing missing teeth or repairing damage, Regent Dental's aim is to help you achieve optimal oral health.
                    </p>
                    <div className="d-flex justify-content-start">
                        <a href="/contact-us" className="btn-theme border-0 mt-3 animate__animated animate__fadeIn" type="button">
                            Book a Consultation
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistry;
