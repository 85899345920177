import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';
import '../../assets/css/team.css';
import 'animate.css';
import 'swiper/css/navigation';

const ProfileSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const swiperRef = useRef(null);

    const goNext = () => {
        if (swiperRef.current) swiperRef.current.slideNext();
    };

    const goPrev = () => {
        if (swiperRef.current) swiperRef.current.slidePrev();
    };

    return (
        <section className="bg-theme">
            <div className="container p-5">
                <div className="d-flex justify-content-center">
                    <div className="text-center text-light">
                        <p className={`${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            You are in good company...
                        </p>
                        <h1 className={`${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            Smashing reviews<br />from our patients
                        </h1>
                    </div>
                </div>

                <Swiper
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    slidesPerView={3}
                    spaceBetween={20}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        1024: { slidesPerView: 3 },
                        640: { slidesPerView: 2 },
                        320: { slidesPerView: 1 },
                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper mt-5"
                >
                    <SwiperSlide>
                        <div className="card rounded-3 bg-light-gold">
                            <div className="card-body p-4">
                                <div className='mb-3'>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                </div>
                                <p className='mb-3'> “You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.” </p>
                                <p className="fw-600">Amnda Cooper</p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="card rounded-3 bg-light-gold">
                            <div className="card-body p-4">
                                <div className='mb-3'>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                </div>
                                <p className='mb-3'> “You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.” </p>
                                <p className="fw-600">Amnda Cooper</p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="card rounded-3 bg-light-gold">
                            <div className="card-body p-4">
                                <div className='mb-3'>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                </div>
                                <p className='mb-3'> “You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.” </p>
                                <p className="fw-600">Amnda Cooper</p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="card rounded-3 bg-light-gold">
                            <div className="card-body p-4">
                                <div className='mb-3'>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                </div>
                                <p className='mb-3'> “You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.” </p>
                                <p className="fw-600">Amnda Cooper</p>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="card rounded-3 bg-light-gold">
                            <div className="card-body p-4">
                                <div className='mb-3'>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                    <i class="fa fa-star text-warning"></i>
                                </div>
                                <p className='mb-3'> “You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.” </p>
                                <p className="fw-600">Amnda Cooper</p>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper>

                {/* Custom Navigation Buttons */}
                <div className="d-flex justify-content-center mt-5">
                    <button onClick={goPrev} className="btn btn-outline-light rounded-circle p-3 me-3 d-flex justify-content-center align-items-center" style={{height: '50px', width: '50px'}}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                    <button onClick={goNext} className="btn btn-outline-light rounded-circle p-3 d-flex justify-content-center align-items-center" style={{height: '50px', width: '50px'}}>
                        <i className="fa fa-arrow-right"></i>
                    </button>
                </div>

            </div>
        </section>
    );
};

export default ProfileSection;
