import React, { useEffect, useRef, useState } from 'react';
import 'animate.css';

import img1 from '../../assets/img/smiles/34.png'
import img2 from '../../assets/img/smiles/35.png'
import img3 from '../../assets/img/smiles/36.png'
import img4 from '../../assets/img/smiles/37.png'
import img5 from '../../assets/img/smiles/38.png'
import img6 from '../../assets/img/smiles/39.png'
import img7 from '../../assets/img/smiles/40.png'
import img8 from '../../assets/img/smiles/41.png'
import img9 from '../../assets/img/smiles/42.png'
import img10 from '../../assets/img/smiles/43.png'
import img11 from '../../assets/img/smiles/44.png'
import img12 from '../../assets/img/smiles/45.png'
import img13 from '../../assets/img/smiles/46.png'
import img14 from '../../assets/img/smiles/47.png'
import img15 from '../../assets/img/smiles/48.png'

const TreatmentOptions = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    const handleScroll = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(true); // Set to true when the section is visible
            } else {
                setIsVisible(false); // Set to false when the section is not visible
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleScroll, {
            threshold: 0.1 // Adjust threshold as needed
        });
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref} className="row p-5">
            <div className="col-12">
                <div className="container d-flex justify-content-center">
                    <div className="text-center">
                        <h1 className={` text-theme mb-5 ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                            Smile Gallery
                        </h1>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img1} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img2} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img3} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img4} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img5} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img6} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img7} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img8} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img9} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img10} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img11} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img12} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img13} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img14} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-5">
                            <div className={`card d-flex flex-column h-100 border-golden rounded ${isVisible ? 'animate__animated animate__zoomIn' : 'animate__animated animate__fadeOut'}`}>
                                <img className="card-img-top" src={img15} className="img-fluid" alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TreatmentOptions;
