import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

const GeneralDentistryProcess = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FFFBF1', minHeight: '95vh' }}>
            <div className='container'>
                <div className="text-center">
                    <h1 className={`mt-5 animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        How General Dentistry <br /> Works 
                        A Simple, Painless Process
                    </h1>
                    <p className={`w-75 mx-auto animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        At Regent Dental we believe in a proactive and preventative approach to dental care. 
                        Using the very latest technology to provide efficient, effective treatments, 
                        whilst providing a comfortable experience for patients of all ages.
                    </p>
                </div>

                <div className="p-4">
                    <div className="row justify-content-center">
                        {[
                            {
                                number: 1,
                                title: "Examinations",
                                description: "A thorough check-up, including oral cancer screenings, digital X-rays and an intraoral scan, to monitor your oral health."
                            },
                            {
                                number: 2,
                                title: "Hygiene Services",
                                description: "A professional cleaning to remove plaque and tartar, above and below the gum, helping to prevent gum disease and decay."
                            },
                            {
                                number: 3,
                                title: "Fillings",
                                description: "The use of high-quality materials to treat cavities to restore the health and function of your teeth."
                            },
                            {
                                number: 4,
                                title: "Crowns and Bridges",
                                description: "For damaged or missing teeth we create custom crowns and bridges that look natural and restore your smile’s integrity."
                            },
                            {
                                number: 5,
                                title: "Family Dentistry",
                                description: "Our dentists offer treatments for patients of all ages, focusing on education and prevention for the youngest visitors."
                            }
                        ].map((service, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card card-body border-0 rounded-4 animate__animated ${isVisible ? 'animate__fadeInUp' : 'animate__fadeOut'}`}>
                                    <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3">
                                        {service.number}
                                    </div>
                                    <div className="fs-18 fw-600">{service.title}</div>
                                    <p className="fs-14">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistryProcess;
