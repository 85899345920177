// src/components/SmileSection.js
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/terms-of-use.css';

const SmileSection = () => {

    return (
        <div className="WordSection1 container mt-5">
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        Welcome to the Regent Dental website.&nbsp;By accessing or using our
                        website, you agree to comply with and be bound by the following terms
                        and conditions of use, which together with our privacy policy govern
                        Regent Dental relationship with you in relation to this website.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;{" "}
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        1. Acceptance of Terms
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        By accessing or using Regent Dental you agree to be bound by these Terms
                        of Use and all applicable laws and regulations. If you do not agree with
                        any part of these terms, you must not use our website.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        2. Changes to Terms
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        We reserve the right to modify or replace these Terms of Use at any
                        time. It is your responsibility to check this page periodically for
                        changes. Your continued use of the website following the posting of any
                        changes constitutes acceptance of those changes.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        3. Use of Website
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        You agree to use our website only for lawful purposes and in a manner
                        that does not infringe the rights of, restrict, or inhibit anyone else's
                        use and enjoyment of the website. Prohibited behaviour includes
                        harassing or causing distress or inconvenience to any other user,
                        transmitting obscene or offensive content, or disrupting the normal flow
                        of dialogue within our website.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        4. Intellectual Property
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        All content on this website, including text, graphics, logos, images,
                        and software, is the property of Regent Dental or its content suppliers
                        and is protected by international copyright laws. Unauthorised use of
                        this content may violate copyright, trademark, and other laws.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        5. Termination
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        We reserve the right to terminate or suspend your access to our website
                        at any time, without notice, for conduct that we believe violates these
                        Terms of Use or is harmful to other users of the website, us, or third
                        parties, or for any other reason.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        6. Limitation of Liability
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        In no event will Regent Dental be liable for any indirect, incidental,
                        special, consequential, or punitive damages arising out of or in
                        connection with your use of our website. Our liability in any
                        circumstance is limited to the amount paid, if any, by you to us for
                        access to the website.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        7. Disclaimer of Warranties
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        Our website is provided "as is" and "as available" without any
                        warranties of any kind, either express or implied. We do not warrant
                        that the website will be uninterrupted, error-free, or free of viruses
                        or other harmful components.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        8. Third-Party Links
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        Our website may contain links to third-party websites that are not owned
                        or controlled by Regent Dental will have no control over, and assume no
                        responsibility for, the content, privacy policies, or practices of any
                        third-party websites. By using our website, you expressly relieve us
                        from any and all liability arising from your use of any third-party
                        website.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        9. Contact Information
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        If you have any questions about these Terms of Use, please contact us at
                        pm@regent-dental.co.uk.
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="normaltextrun">
                    <span lang="EN-US" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        By using our website, you acknowledge that you have read, understood,
                        and agree to be bound by these terms and conditions.
                    </span>
                </span>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="paragraph" style={{ margin: "0cm", verticalAlign: "baseline" }}>
                <span className="eop">
                    <span lang="EN-GB" style={{ fontFamily: '"Aptos",sans-serif' }}>
                        &nbsp;
                    </span>
                </span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
            <p className="MsoNormal">
                <span lang="EN-GB">&nbsp;</span>
            </p>
        </div>


    );
};

export default SmileSection;
