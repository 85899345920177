import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import teamImage from '../../assets/img/64e76a74015a878b61b17cf0_swish-team-image-p-800.webp.png'; // Adjust the path as necessary

const RegentDentalExperience = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="hero-3 d-flex justify-content-center align-items-center bg-theme text-light p-4" style={{ minHeight: '85vh' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 px-lg-3">
                        <div>
                            <p className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                The Regent Dental Experience
                            </p>
                            <h1 className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                Aftercare and <span className="text-golden">Maintenance</span>
                            </h1>
                            <p className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                To keep your smile shining bright, we'll guide you through the dos and don'ts post-whitening and suggest 
                                lifestyle adjustments that can help maintain the results. You can visit us to top-up your whitening gels too..
                            </p>
                        </div>
                        <div>
                            <h1 className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                The Power of a <span className="text-golden">Whiter Smile</span>
                            </h1>
                            <p className={`animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                A whiter smile not only enhances your appearance but also boosts your confidence in both personal and professional interactions. 
                                Let us help you unlock the power of your smile
                            </p>
                        </div>
                        <div>
                            <a href="/team" className={`btn btn-golden mt-5 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}>
                                Meet the Team
                            </a>
                        </div>
                    </div>

                    <div className="col-md-6 p-5">
                        <img
                            src={teamImage}
                            className={`img-fluid shadow-golden-2 animate__animated ${isVisible ? 'animate__zoomIn' : 'animate__fadeOut'}`}
                            style={{ borderTopLeftRadius: '350px', borderTopRightRadius: '350px' }}
                            alt="Team"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegentDentalExperience;
