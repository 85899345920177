import { Helmet } from 'react-helmet';
import Navbar from '../components/offer-page/navbar';
import Header from '../components/offer-page/hero';
import Offers from '../components/offer-page/offers';
import Mission from '../components/offer-page/mission';
import Testimonials from '../components/universal/testimonials';
import Contact from '../components/universal/contact';
import Footer from '../components/universal/footer';


function Index() {
    return (
        <div>
            <Helmet>
                <title>Local Dentist in Ilkley, West Yorkshire - Special Offer </title>
            </Helmet>
            <Navbar />
            <Header />
            <Offers />
            <Mission />
            <Testimonials />
            <Contact />
            <Footer />
        </div>
    );
}

export default Index;