import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

const GeneralDentistryProcess = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FFFBF1', minHeight: '95vh' }}>
            <div className='container'>
                <div className="text-center">
                    <h1 className={`mt-5 animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        How General Dentistry <br /> Works 
                        A Simple, Painless Process
                    </h1>
                    <p className={`w-75 mx-auto animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                    As the exclusive NiTime aligner provider in West Yorkshire, we use the latest intra oral scanning technology 
                    to help simulate your teeth straightening journey so we can get you to your new smile as smoothly as possible.
                    </p>
                </div>

                <div className="p-4">
                    <div className="row justify-content-center align-items-stretch">
                        {[
                            {
                                number: 1,
                                title: "Assessment",
                                description: "A detailed examination and digital scans form the basis for your custom aligner treatment."
                            },
                            {
                                number: 2,
                                title: "Watch the Process",
                                description: "You'll see a 3D simulation of your tooth movement over the course of your proposed treatment."
                            },
                            {
                                number: 3,
                                title: "Custom Aligners",
                                description: "A series of custom aligners are designed and manufactured that will move your teeth."
                            },
                            {
                                number: 4,
                                title: "Progress Monitoring",
                                description: "With regular check-ups, your progress is monitored as the treatment progresses."
                            },
                            {
                                number: 5,
                                title: "Retention",
                                description: "You'll get fitted with a retainer to maintain your new smile and prevent future shifting."
                            }
                        ].map((service, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 animate__animated ${isVisible ? 'animate__fadeInUp' : 'animate__fadeOut'}`}>
                                    <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3">
                                        {service.number}
                                    </div>
                                    <div className="fs-18 fw-600">{service.title}</div>
                                    <p className="fs-14">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistryProcess;
