import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';

const GeneralDentistryProcess = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#FFFBF1', minHeight: '95vh' }}>
            <div className='container'>
                <div className="text-center mt-5">
                    <h1 className={`animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        How General Dentistry Works <br />
                        A Simple, Painless Process
                    </h1>
                    <p className={`w-75 mx-auto animate__animated ${isVisible ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        We utilise the latest magnification equipment and pain management to ensure your comfort throughout the process. 
                        You'll be talked through each step of the treatment before we begin so you know you're in the safest hands.
                    </p>
                </div>

                <div className="p-4">
                    <div className="row justify-content-center align-items-stretch">
                        {[
                            {
                                number: 1,
                                title: "Diagnosis",
                                description: "One of our dentists begins with a thorough examination, including X-rays, to assess the extent of the infection."
                            },
                            {
                                number: 2,
                                title: "Anaesthesia",
                                description: "A local anaesthetic is used to numb the area, ensuring you feel no discomfort during the procedure. Removing the Infection: The infected pulp is carefully removed, the root canal is cleaned and shaped, preparing it for a filling."
                            },
                            {
                                number: 3,
                                title: "Removing the Infection",
                                description: "The infected pulp is carefully removed, the root canal is cleaned and shaped, preparing it for a filling."
                            },
                            {
                                number: 4,
                                title: "Filling the Tooth",
                                description: "The cleaned root canal is filled and sealed with a material that prevents bacteria from re-entering."
                            },
                            {
                                number: 5,
                                title: "Restoration",
                                description: "A crown or filling is then placed on the tooth to restore its natural shape, appearance, and function."
                            }
                        ].map((service, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className={`card h-100 card-body border-0 rounded-4 animate__animated ${isVisible ? 'animate__fadeInUp' : 'animate__fadeOut'}`}>
                                    <div style={{ height: '50px', width: '50px' }} className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3">
                                        {service.number}
                                    </div>
                                    <div className="fs-18 fw-600">{service.title}</div>
                                    <p className="fs-14">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralDentistryProcess;
