import React from 'react';

const Breadcrumb = ({ breadcrumbs }) => {
    return (
        <nav className="breadcrumb" style={{ marginTop: '100px' }}>
            {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={index}>
                    {breadcrumb.isActive ? (
                        <span className="breadcrumb-item active fs-14" aria-current="page">
                            {breadcrumb.label}
                        </span>
                    ) : (
                        <a
                            className="breadcrumb-item text-theme text-decoration-none fs-14"
                            href={breadcrumb.href}
                        >
                            {breadcrumb.label}
                        </a>
                    )}
                </React.Fragment>
            ))}
        </nav>
    );
};

export default Breadcrumb;
