import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import docImage from '../../assets/img/doc-white.png'; // Import your image

const DentalCareSection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null); // Reference to the section for intersection observer

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Trigger animation when in view
                } else {
                    setIsVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 } // Adjust threshold as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className="bg-theme">
            <div className="container">
                <div className="row align-items-center">

                    <div className={`col-md-6 mt-4 animate__animated ${isVisible ? 'animate__fadeInLeft' : 'animate__fadeOut'}`}>
                        <img src={docImage} className="img-fluid" alt="Dental Care" />
                    </div>

                    <div className={`col-md-6 text-light mt-4 animate__animated ${isVisible ? 'animate__fadeInRight' : 'animate__fadeOut'}`}>
                        <p>Dental Care You'll Love</p>

                        <h1 className="fs-44">Comprehensive Care for Lasting Oral Health</h1>

                        <p>
                            General dentistry encompasses a variety of dental treatments aimed at helping you maintain healthy teeth and gums. 
                            It’s the foundation of dental care, ensuring that your smile stays in excellent condition and functions at its best.
                        </p>
                        <p>
                            Regular dental visits are crucial for the early detection and prevention of oral health issues. By addressing small
                            problems before they escalate, it saves you time and discomfort. All of this helps you avoid the need for more complex treatments.
                        </p>

                        <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">Book an Appointment</a>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default DentalCareSection;
